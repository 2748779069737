import React, { useState } from 'react';
import { PhysicianResult } from '../models/api';
import { List, ListItemText, ListItem, ListItemIcon, ListSubheader, Box, Dialog, DialogContent, Button, Typography, Tooltip, IconButton, useTheme, useMediaQuery, Tabs, Tab, Slide } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { PhysicianAvatar } from '../components/PhysicianAvatar';
import { useUser } from '../models/auth';
import LoginDialog from '../components/LoginDialog';
import CloseIcon from "@material-ui/icons/Close";
import EmailIcon from "@material-ui/icons/Email";
import WebAssetIcon from "@material-ui/icons/WebAsset";
import PhoneIcon from "@material-ui/icons/Phone";
import AdminTheme from '../components/AdminTheme';

type PhysicianDialogInputs = {
    physician: PhysicianResult | null,
    onClose: () => void
};

const PhysicianDialog = ({ physician, onClose }: PhysicianDialogInputs) => {
    const { user } = useUser();
    const [showLogin, setShowLogin] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [tab, setTab] = useState(0);

    return (
        <Dialog
            fullScreen={fullScreen}
            open={physician !== null}
            onClose={onClose}
            TransitionComponent={Transition}
            aria-labelledby="physician-dialog-title"
        >
            <DialogContent style={{ padding: "0" }}>
                <Typography variant="srOnly" id="physician-dialog-title">
                    {physician?.displayName} {/* This is only displayed to screen readers */}
                </Typography>
                {physician && (
                    <div>
                        <Box
                            bgcolor="primary.main"
                            color="primary.contrastText"
                        >
                            <Box
                                style={{
                                    display: "flex",
                                    flexDirection: "row-reverse",
                                    marginRight: "1rem"
                                }}
                            >
                                <Tooltip title="Close">
                                    <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <Box
                                style={{
                                    padding: "0 2rem 1rem 2rem",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center"
                                }}
                            >
                                <Box
                                    border={2}
                                    borderRadius="50%"
                                >
                                    <PhysicianAvatar
                                        avatarSize={120}
                                        imageID={physician.imageID}
                                        displayName={physician.displayName}
                                    />
                                </Box>
                                <Typography variant="h6">
                                    {physician.displayName}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {physician.title}
                                </Typography>
                            </Box>

                            <Tabs value={tab} onChange={(e, value: number) => setTab(value)} aria-label="physician details tabs">
                                <Tab label="Contact" {...a11yProps(0)} />
                                <Tab label="Locations" {...a11yProps(1)} />
                                <Tab label="Specialties" {...a11yProps(2)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={tab} index={0}>
                            {!user && (
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    mx={2}
                                    my={2}
                                >
                                    <Typography
                                        variant="body1"
                                        style={{
                                            maxWidth: "320px",
                                            marginBottom: "1rem"
                                        }}
                                    >
                                        Physicians, sign in to see non-public mobile numbers, email addresses, and URLs.
                                    </Typography>
                                    <AdminTheme>
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            style={{ marginLeft: "0.5rem" }}
                                            onClick={() => setShowLogin(true)}
                                        >
                                            Sign In
                                        </Button>
                                    </AdminTheme>
                                </Box>
                            )}
                            <List
                                aria-labelledby="phone-list-subheader"
                                subheader={
                                    <ListSubheader component="div" id="phone-list-subheader">
                                        Phones
                                    </ListSubheader>
                                }
                            >
                                {user && physician.phones.length === 0 && (
                                    <ListItem>
                                        <ListItemText
                                            primary="There are no phone numbers."
                                        />
                                    </ListItem>
                                )}
                                {!user && physician.phones.length === 0 && (
                                    <ListItem>
                                        <ListItemText
                                            primary="There are no public phone numbers."
                                        />
                                    </ListItem>
                                )}
                                {physician.phones.map((item, index) => (
                                    <ListItem
                                        key={index}
                                        button
                                        onClick={() => {
                                            if (item.number) {
                                                window.open(`tel:${item.number}`);
                                            }
                                        }}
                                    >
                                        <ListItemIcon>
                                            <PhoneIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={item.number}
                                            secondary={item.label}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                            <List
                                aria-labelledby="email-list-subheader"
                                subheader={
                                    <ListSubheader id="email-list-subheader">
                                        Emails
                                    </ListSubheader>
                                }
                            >
                                {user && physician.emails.length === 0 && (
                                    <ListItem>
                                        <ListItemText
                                            primary="There are no emails."
                                        />
                                    </ListItem>
                                )}
                                {!user && physician.emails.length === 0 && (
                                    <ListItem>
                                        <ListItemText
                                            primary="There are no public emails."
                                        />
                                    </ListItem>
                                )}
                                {physician.emails.map((item, index) => (
                                    <ListItem
                                        key={index}
                                        button
                                        onClick={() => window.open(`mailto:${item.email}`)}
                                    >
                                        <ListItemIcon>
                                            <EmailIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={item.email}
                                        />
                                    </ListItem>
                                ))}

                            </List>
                            <List
                                aria-labelledby="url-list-subheader"
                                subheader={
                                    <ListSubheader id="url-list-subheader">
                                        Websites
                                    </ListSubheader>
                                }
                            >
                                {user && physician.urls.length === 0 && (
                                    <ListItem>
                                        <ListItemText
                                            primary="There are no websites."
                                        />
                                    </ListItem>
                                )}
                                {!user && physician.urls.length === 0 && (
                                    <ListItem>
                                        <ListItemText
                                            primary="There are no public websites."
                                        />
                                    </ListItem>
                                )}
                                {physician.urls.map((item, index) => (
                                    <ListItem
                                        key={index}
                                        button
                                        onClick={() => {
                                            if (item.url) {
                                                window.open(item.url, "_blank");
                                            }
                                        }}
                                    >
                                        <ListItemIcon>
                                            <WebAssetIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={item.label}
                                        />
                                    </ListItem>
                                ))}

                            </List>
                        </TabPanel>
                        <TabPanel value={tab} index={1}>
                            <List>
                                {physician.locations.length === 0 && (
                                    <ListItem>
                                        <ListItemText
                                            primary="There are no locations available."
                                        />
                                    </ListItem>
                                )}
                                {physician.locations.map((item, index) => (
                                    <ListItem key={index}>
                                        <ListItemText
                                            primary={item.label}
                                        />
                                    </ListItem>
                                ))}

                            </List>
                        </TabPanel>
                        <TabPanel value={tab} index={2}>
                            <List>
                                {physician.specialties.length === 0 && (
                                    <ListItem>
                                        <ListItemText
                                            primary="There are no specialties available."
                                        />
                                    </ListItem>
                                )}
                                {physician.specialties.map((item, index) => (
                                    <ListItem key={index}>
                                        <ListItemText
                                            primary={item.label}
                                        />
                                    </ListItem>
                                ))}

                            </List>
                        </TabPanel>
                    </div>
                )}
            </DialogContent>
            <LoginDialog
                open={showLogin}
                onSuccess={() => setShowLogin(false)}
                onClose={() => setShowLogin(false)}
            />
        </Dialog>
    );
};

const a11yProps = (index: number) => {
    return {
        id: `pd-tab-${index}`,
        'aria-controls': `pd-tabpanel-${index}`,
    };
};

type TabPanelProps = {
    index: number;
    value: number;
};

const TabPanel: React.FC<TabPanelProps> = ({ index, value, children, ...other }) => {
    return (
        <Box
            hidden={value !== index}
            role="tabpanel"
            id={`pd-tabpanel-${index}`}
            aria-labelledby={`pd-tab-${index}`}
            {...other}
        >
            {children}
        </Box>
    );
};

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default PhysicianDialog;