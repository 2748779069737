import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// the above polyfills must be listed first to support IE 11  (use ie9 for >= 9)
import 'abortcontroller-polyfill';  // polyfills fetch AbortController on IE 11
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { retrieveToken } from './models/auth';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { RestfulProvider } from 'restful-react';
import { apiUrl } from './models/config';
import { SnackbarProvider } from 'notistack';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#012169",
        },
        secondary: {
            main: "#00aeef",
            contrastText: "#ffffff"
        },
        error: {
            main: "#da291c"
        },
        success: {
            main: "#348338"
        },
    },
    // status: {
    //     danger: 'orange',
    // },
    typography: {
        button: {
            textTransform: "none"
        }
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: "0.8rem"
            }
        }
    }
});

const appendBearer = () => {
    const headersPartial: Partial<RequestInit> = {};
    const token = retrieveToken();
    if (token !== null) {
        headersPartial.headers = {
            "Authorization": `Bearer ${token}`,
        };
    }
    return headersPartial;
}

ReactDOM.render(
    <RestfulProvider base={apiUrl} requestOptions={appendBearer}>
        <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3}>
                <App />
            </SnackbarProvider>
        </ThemeProvider>
    </RestfulProvider>
    , document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
