import React from 'react';
import { TextField } from "formik-material-ui";
import { ApplyExternalRequest, usePostUserApplyExternal } from '../models/api';
import { Formik, Form, Field } from "formik";
import { getRemoteErrors } from '../models/client';
import { Typography, Button, MenuItem, DialogContent, DialogActions, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import AdminTheme from './AdminTheme';

type InputProps = {
    onSubmit: () => void,
    onClose: () => void
}

const ApplyExternal = ({ onSubmit, onClose }: InputProps) => {
    const theme = useTheme();
    const isSmallScreenOrLess = useMediaQuery(theme.breakpoints.down('sm'));
    const { mutate: applyExternal } = usePostUserApplyExternal({});
    const gridSpacing = isSmallScreenOrLess ? 0 : 3;

    return (
        <Formik<ApplyExternalRequest & { generalError: string | null }>
            initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                company: "",
                street: "",
                city: "",
                state: "",
                zip: "",
                phoneType: "Mobile",
                number: "",
                generalError: null
            }}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
                try {
                    await applyExternal(values);
                    onSubmit();
                } catch (ex) {
                    if (ex.data) {
                        const errors = getRemoteErrors(ex.data);
                        console.log(errors);
                        setErrors(errors);
                    } else {
                        setErrors({
                            generalError: ex.message
                        });
                    }
                    setSubmitting(false);
                }
            }}
        >
            {({ isSubmitting, errors }) => (
                <Form autoComplete="on">
                    <DialogContent>
                        {errors.generalError && <Typography variant="subtitle1" gutterBottom color="error">{errors.generalError}</Typography>}
                        
                        
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12} md={6}>
                                <Field
                                    name="firstName"
                                    label="First Name"
                                    component={TextField}
                                    margin="normal"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field
                                    name="lastName"
                                    label="Last Name"
                                    component={TextField}
                                    margin="normal"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Field
                            name="email"
                            label="Email Address"
                            component={TextField}
                            margin="normal"
                            fullWidth
                        />
                        <Field
                            name="password"
                            label="Password"
                            type="password"
                            component={TextField}
                            margin="normal"
                            fullWidth
                        />
                        <Typography variant="h6" style={{ marginTop: "1em" }}>
                            Office
                        </Typography>
                        <Field
                            name="company"
                            label="Company Name"
                            component={TextField}
                            margin="normal"
                            fullWidth
                        />
                        <Field
                            name="street"
                            label="Street Address"
                            component={TextField}
                            margin="normal"
                            fullWidth
                        />
                        <Grid container spacing={gridSpacing} >
                            <Grid item xs={12} md={6}>
                                <Field
                                    name="city"
                                    label="City"
                                    component={TextField}
                                    margin="normal"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Field
                                    name="state"
                                    label="State"
                                    component={TextField}
                                    margin="normal"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Field
                                    name="zip"
                                    label="Postal Code"
                                    component={TextField}
                                    margin="normal"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12} md={3}>
                                <Field
                                    name="phoneType"
                                    label="Phone type"
                                    component={TextField}
                                    margin="normal"
                                    fullWidth
                                    type="text"
                                    select
                                >
                                    <MenuItem value="Office">Office</MenuItem>
                                    <MenuItem value="Mobile">Mobile</MenuItem>
                                </Field>
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <Field
                                    name="number"
                                    label="Phone Number"
                                    component={TextField}
                                    margin="normal"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>





                    </DialogContent>
                    <DialogActions>
                        <AdminTheme>
                            <Button onClick={onClose} color="primary" disabled={isSubmitting}>
                                Cancel
                            </Button>
                        </AdminTheme>
                        <Button type="submit" color="secondary" variant="contained" disabled={isSubmitting}>
                            Apply
                        </Button>
                    </DialogActions>
                </Form>
            )}
        </Formik>
    );
}

export default ApplyExternal;