import React, { useState, useEffect } from 'react';
//import { useHistory } from "react-router-dom";
import { useGetUserAdmin, UserListItemAdmin, UserApprovalState, UserType, usePostUserAdmin } from '../../models/api';
import { Button } from '@material-ui/core';
import MaterialTable, { MTableAction } from 'material-table';
import { useSnackbar } from 'notistack';
import AddEmoryUser from '../components/AddEmoryUser';
import AddIcon from "@material-ui/icons/Add";

const UserListAdmin = () => {
    //const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [items, setItems] = useState<UserListItemAdmin[] | null>(null);
    const { data: itemsOnServer, loading, error, refetch } = useGetUserAdmin({});
    const { mutate: saveUser } = usePostUserAdmin({});

    useEffect(() => {
        setItems(itemsOnServer);
    }, [itemsOnServer]);

    if (error) {
        return (
            <div>
                <h1>There was an error loading.</h1>
                <p>Please refresh the page.</p>
            </div>
        );
    }

    return (
        <div style={{ maxWidth: "100%" }}>
            <MaterialTable
                title="Users"
                data={items ?? []}
                isLoading={loading}
                options={{
                    paging: false,
                    exportButton: true,
                    exportFileName: "Users",
                    filtering: true,
                    hideFilterIcons: true
                }}
                // onRowClick={(e, rowData) => {
                //     if (!rowData) {
                //         return;
                //     }
                //     history.push(`/user/${rowData.id}`);
                // }}
                editable={{
                    onRowUpdate: async (newData) => {
                        try {
                            if (items === null) {
                                throw new Error("items are empty");
                            }
                            const id = newData.id;
                            await saveUser({
                                id: id,
                                isAdmin: newData.isAdmin,
                                approvalState: newData.approvalState
                            });
                            const _items = [...items];
                            const index = _items.findIndex(o => o.id === id);
                            _items[index] = newData;
                            setItems(_items);
                        } catch (err) {
                            console.log(err);
                            enqueueSnackbar(`There was an error saving your changes to ${newData.name}.`, { variant: "error" });
                            throw err;
                        }
                    }
                }}
                localization={{
                    header: {
                        actions: ""
                    }
                }}
                actions={[
                    {
                        icon: "add",
                        isFreeAction: true,
                        onClick: () => {}  // handled in compoents override
                        //onClick: () => history.push("/user/addAdmin")
                    }
                ]}
                components={{
                    Action: (props) => {
                        if (props.action.icon === "add") {
                            return (<AddButton onAdd={refetch} />);
                        }
                        return <MTableAction {...props} />;
                    }
                }}
                columns={[
                    {
                        field: "name",
                        title: "Name",
                        editable: "never"
                    },
                    {
                        field: "type",
                        title: "Type",
                        editable: "never",
                        lookup: {
                            ["Emory" as UserType]: "Emory",
                            ["NonEmory" as UserType]: "NonEmory"
                        }
                    },
                    {
                        field: "email",
                        title: "Email",
                        editable: "never"
                    },
                    {
                        field: "approvalState",
                        title: "Approved",
                        lookup: {
                            ["Unknown" as UserApprovalState]: "Unknown",
                            ["Approved" as UserApprovalState]: "Approved",
                            ["Denied" as UserApprovalState]: "Denied"
                        }
                    },
                    {
                        field: "isAdmin",
                        title: "Administrator",
                        editable: (columnDef, rowData) => rowData.type === "Emory",
                        lookup: {
                            true: "Yes",
                            false: "No"
                        }
                    },
                    {
                        field: "company",
                        title: "Company",
                        editable: "never"
                    }
                ]}
            />
        </div>
    );
}

type AddButtonProps = {
    onAdd: () => Promise<void>
};

const AddButton = ({ onAdd }: AddButtonProps) => {
    const [showDialog, setShowDialog] = useState(false);

    return (
        <>
            <Button
                color="secondary"
                variant="contained"
                style={{ marginLeft: "0.5rem " }}
                onClick={() => setShowDialog(true)}
                startIcon={<AddIcon />}
            >
                Add Emory User
            </Button>
            <AddEmoryUser 
                show={showDialog}
                onSubmit={async () => {
                    setShowDialog(false);
                    await onAdd();
                }}
                onCancel={() => setShowDialog(false)}
            />
        </>
    );
};


export default UserListAdmin;