import axios, { AxiosRequestConfig } from "axios";
import { apiUrl } from "./config";
import { retrieveToken } from "./auth";

const makeUrl = (url: string) =>
    `${apiUrl}/api${url}`;

const client = axios.create({
    baseURL: `${apiUrl}/api`,
    responseType: "json",
    headers: {
        "Content-Type": "application/json"
    }
});

const authRequestHandler = (request: AxiosRequestConfig) => {
    const token = retrieveToken();
    if (token !== null) {
        request.headers["Authorization"] = `Bearer ${token}`;
    }
    return request;
}

client.interceptors.request.use(authRequestHandler);

export {
    client,
    makeUrl
};




type ErrorReponse = {
    errors: Record<string, string[] | string | number>
};

export const getRemoteErrors = ({ errors }: ErrorReponse) => {
    const flatErrors: Record<string, string> = {};

    Object.keys(errors).forEach(key => {
        const errorArray = errors[key] as string[];
        if (!!errorArray) {
            flatErrors[toCamelCase(key)] = errorArray.join(" ");
        }
    })

    return flatErrors;
}

const toCamelCase = (str: string) => {
    if (str.length === 0) return str;
    if (str.length === 1) return str.toLowerCase();
    return str.charAt(0).toLowerCase() + str.slice(1);
}