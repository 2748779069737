import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useUser } from '../models/auth';
import { ErrorScreen } from './ErrorScreen';

const AdminRoute = ({ component: Component, ...rest }: RouteProps) => {
    const { user } = useUser();
    if (!Component) return null;
    return (
        <Route {...rest} render={props => {
            if (user === null) {
                return <Redirect push={true} to={`/signin?returnurl=${props.location.pathname}`} />;
            }
            if (user.isAdmin) {
                return <Component {...props} />;
            }
            return <ErrorScreen message="You do not have access to this resource." />;
        }} />
    );
}

export default AdminRoute;