import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useUser } from '../models/auth';
import { ErrorScreen } from './ErrorScreen';

const UserRoute = ({ component: Component, ...rest }: RouteProps) => {
    const { user } = useUser();
    if (!Component) return null;
    return (
        <Route {...rest} render={props => {
            if (user === null) {
                return <ErrorScreen message="You must be signed in to view this page." />;
            }
            return <Component {...props} />;
        }} />
    );
}

export default UserRoute;