import React, { useState, useEffect } from 'react';
import { useGetPhysicianAdmin, PhysicianAdminResult } from '../../models/api';
import { Button, List, ListItemText, ListItem, Slide, Dialog } from '@material-ui/core';
import MaterialTable, { MTableAction } from 'material-table';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import EditPhysician from '../components/EditPhysician';
import { pictureUrl } from '../../models/config';
import AddIcon from "@material-ui/icons/Add";

const PhysicianListAdmin = () => {
    const [showModalAdd, setShowModalAdd] = useState(false);
    const [editingPhysician, setEditingPhysician] = useState<PhysicianAdminResult | null>(null);
    const [items, setItems] = useState<PhysicianAdminResult[] | null>(null);
    const { data: itemsOnServer, loading, error, refetch } = useGetPhysicianAdmin({});

    useEffect(() => {
        setItems(itemsOnServer);
    }, [itemsOnServer]);

    if (error) {
        return (
            <div>
                <h1>There was an error loading.</h1>
                <p>Please refresh the page.</p>
            </div>
        );
    }

    return (
        <div style={{ maxWidth: "100%" }}>
            <MaterialTable
                title="Physician administration"
                data={items ?? []}
                isLoading={loading}
                options={{
                    paging: false,
                }}
                onRowClick={(e, rowData) => {
                    if (!rowData) {
                        return;
                    }
                    setEditingPhysician(rowData);
                }}
                localization={{
                    header: {
                        actions: ""
                    }
                }}
                actions={[
                    {
                        icon: "add",
                        isFreeAction: true,
                        onClick: () => {}  // handled in compoents override
                    }
                ]}
                components={{
                    Action: (props) => {
                        if (props.action.icon === "add") {
                            return (
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    style={{ marginLeft: "0.5rem " }}
                                    onClick={() => setShowModalAdd(true)}
                                    startIcon={<AddIcon />}
                                >
                                    Add Physician
                                </Button>
                            );
                        }
                        return <MTableAction {...props} />;
                    }
                }}
                columns={[
                    {
                        field: "firstName",
                        title: "First Name"
                    },
                    {
                        field: "lastName",
                        title: "Last Name"
                    },
                    {
                        field: "displayName",
                        title: "Display Name"
                    },
                    {
                        field: "title",
                        title: "Title"
                    },
                    {
                        field: "published",
                        title: "Published",
                        lookup: {
                            true: "Yes",
                            false: "No"
                        }
                    },
                    {
                        field: "phones",
                        title: "Phones",
                        render: (data) => {
                            return (
                                <List dense>
                                    {data.phones
                                        .map((item, index) => (
                                            <ListItem key={index} disableGutters>
                                                <ListItemText 
                                                    primary={`${item.label} ${(item.isPublic ?? false) ? " (Secure)" : ""}`}
                                                    secondary={item.number}
                                                />
                                            </ListItem>
                                        ))}
                                </List>
                            );
                        }
                    },
                    {
                        field: "emails",
                        title: "Emails",
                        render: (data) => {
                            return (
                                <List dense>
                                    {data.emails
                                        .map((item, index) => (
                                            <ListItem key={index} disableGutters>
                                                <ListItemText
                                                    primary={`${item.email} ${(item.isPublic ?? false) ? " (Secure)" : ""}`}
                                                />
                                            </ListItem>
                                        ))}
                                </List>
                            );
                        }
                    },
                    {
                        field: "urls",
                        title: "URLs",
                        render: (data) => {
                            return (
                                <List dense>
                                    {data.urls
                                        .map((item, index) => (
                                            <ListItem key={index} disableGutters>
                                                <ListItemText
                                                    primary={`${item.label} ${(item.isPublic ?? false) ? " (Secure)" : ""}`}
                                                />
                                            </ListItem>
                                        ))}
                                </List>
                            );
                        }
                    },
                    {
                        field: "specialties",
                        title: "Specialties",
                        render: (data) => {
                            return (
                                <List dense>
                                    {data.specialties
                                        .map((item, index) => (
                                            <ListItem key={index} disableGutters>
                                                <ListItemText
                                                    primary={item.label}
                                                />
                                            </ListItem>
                                        ))}
                                </List>
                            );
                        }
                    },
                    {
                        field: "locations",
                        title: "Locations",
                        render: (data) => {
                            return (
                                <List dense>
                                    {data.locations
                                        .map((item, index) => (
                                            <ListItem key={index} disableGutters>
                                                <ListItemText
                                                    primary={item.label}
                                                />
                                            </ListItem>
                                        ))}
                                </List>
                            );
                        }
                    },
                    {
                        field: "imageID",
                        title: "Image",
                        render: (data) => {
                            if (data.imageID) {
                                return (
                                    <img 
                                        src={pictureUrl(data.imageID)}
                                        style={{
                                            maxWidth: "70px",
                                            maxHeight: "70px"
                                        }}
                                        alt={data.displayName ?? "No name provided"} />
                                );
                            }
                            return <span>None</span>
                        }
                    }
                ]}
            />
            <ModalDialog 
                title="Add physician"
                isOpen={showModalAdd}
                onClose={() => setShowModalAdd(false)}
                onSave={async () => {
                    setShowModalAdd(false);
                    await refetch();
                }}
            />
            <ModalDialog
                title="Edit physician"
                isOpen={editingPhysician !== null}
                physician={editingPhysician}
                onClose={() => setEditingPhysician(null)}
                onSave={async () => {
                    setEditingPhysician(null);
                    await refetch();
                }}
            />
        </div>
    );
}

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



type ModalDialogInputs = {
    isOpen: boolean,
    title: string,
    physician?: PhysicianAdminResult | null,
    onClose: () => void,
    onSave: () => Promise<void>,
};

const ModalDialog= ({ isOpen, title, physician, onClose, onSave }: ModalDialogInputs) => {
    
    return (
        <Dialog
            fullScreen
            open={isOpen}
            onClose={onClose}
            TransitionComponent={Transition}
        >
            <EditPhysician title={title} physician={physician} onClose={onClose} onSave={onSave} />
        </Dialog>
    );
};

export default PhysicianListAdmin;