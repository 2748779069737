import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Link as RouterLink, useHistory } from "react-router-dom";
import { Button, CssBaseline, AppBar, Toolbar, Typography, Menu, MenuItem, Divider, Chip, useTheme, useMediaQuery, Box, Drawer, IconButton, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { UserProvider, UserConsumer, useUser, tokenKey, deleteCookie, signOut } from './models/auth';
import LoginPage from './pages/LoginPage';
import AdminRoute from './components/AdminRoute';
import UserListAdmin from './admin/pages/UserListAdmin';
import PhysicianListAdmin from './admin/pages/PhysicianListAdmin';
import PhysicianDashboard from './pages/PhysicianDashboard';
import LoginDialog from './components/LoginDialog';
import AdminTheme from "./components/AdminTheme";
import PasswordResetPage from "./pages/PasswordResetPage";
import CalendarPage from './pages/CalendarPage';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PeopleIcon from '@material-ui/icons/People';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import PersonIcon from '@material-ui/icons/Person';
import EditUserPage from './pages/EditUserPage';
import UserRoute from './components/UserRoute';

// function usePageViews() {
//   let location = useLocation()

//   useEffect(
//     () => {
//       ga.send(['pageview', location.pathname])
//     },
//     [location]
//   )
// }




const App = () => {
  const [showLogin, setShowLogin] = useState(false);
  const theme = useTheme();
  const mediumScreenAndUp = useMediaQuery(theme.breakpoints.up("md"));
  const xsScreenAndDown = useMediaQuery(theme.breakpoints.down("xs"));
  const useDrawer = xsScreenAndDown;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const title = mediumScreenAndUp
    ? "Cardiothoracic Surgery Directory"
    : "CT Surgery";

  const isApp = (navigator?.userAgent ?? "").toLowerCase().indexOf("CTDirectoryApp".toLowerCase()) > -1;

  return (
    <UserProvider>
      <UserChangeListener />
      <UserConsumer>
        {({ user }) => {
          const isAdmin = user?.isAdmin ?? false;
          return (
            <Router basename="/">
              <CssBaseline />
              <AppBar position="relative">
                <Toolbar style={{ justifyContent: "space-between" }}>
                  <Box component="div" display="flex" flexGrow="1" alignItems="center">
                    {useDrawer && (
                      <IconButton onClick={() => setDrawerOpen(true)} style={{ marginRight: "12px" }}>
                        <MenuIcon style={{ color: "#ffffff" }} />
                      </IconButton>
                    )}
                    <img
                      src="/emorylogo.png"
                      alt="Emory Heart and Vascular Center Logo"
                      style={{
                        height: "40px",
                        width: "auto",
                        marginRight: "1rem"
                      }}
                    />
                    <Typography variant="h6">
                      {title}
                    </Typography>
                  </Box>
                  {!useDrawer && (
                    <Box component="div" display="flex">
                      <Button color="inherit" component={RouterLink} to="/">Physicians</Button>
                      <Button color="inherit" component={RouterLink} to="/calendar">Calendar</Button>
                      {user && <UserButton />}
                      {!user && (
                        <Button
                          color="secondary"
                          variant="contained"
                          style={{ marginLeft: "0.5rem" }}
                          onClick={() => setShowLogin(true)}
                        >
                          Sign In
                      </Button>
                      )}
                    </Box>
                  )}
                </Toolbar>
              </AppBar>

              <AppDrawer 
                drawerOpen={drawerOpen}
                closeDrawer={() => setDrawerOpen(false)}
                showLogin={() => setShowLogin(true)}
              />

              <main style={{ 
                paddingTop: isAdmin ? "0" : "1rem", 
                paddingBottom: "5rem", 
                paddingLeft: xsScreenAndDown ? "0" : "1rem", 
                paddingRight: xsScreenAndDown ? "0" : "1rem", 
              }}>
                {isAdmin && !isApp && (
                  <AdminTheme>
                    <Toolbar variant="dense" style={{ justifyContent: "flex-end" }} >
                      <Chip color="primary" label="Admin menu" size="medium" style={{ marginRight: "0.5rem" }} />
                      <Button color="primary" component={RouterLink} to="/admin">Users</Button>
                      <Button color="primary" component={RouterLink} to="/admin/physicians">Physicians</Button>
                    </Toolbar>
                  </AdminTheme>
                )}
                <Route exact path="/" component={PhysicianDashboard} />
                <Route exact path="/signin" component={LoginPage} />
                <Route exact path="/user/reset/:code" component={PasswordResetPage} />
                <UserRoute exact path="/user/edit" component={EditUserPage} />
                <Route exact path="/calendar" component={CalendarPage} />
                <AdminRoute exact path="/admin" component={UserListAdmin} />
                <AdminRoute exact path="/admin/physicians" component={PhysicianListAdmin} />
              </main>
              <LoginDialog 
                open={showLogin}
                onSuccess={() => setShowLogin(false)}
                onClose={() => setShowLogin(false)}
              />
            </Router>
          );
        }}
      </UserConsumer>
    </UserProvider>
  );
}

const useUserStorageChangeListener = () => {
  const { setUser } = useUser();

  useEffect(() => {
    const storageChangeHandler = (e: StorageEvent) => {
      if (e.key === tokenKey && e.newValue === null) {
        setUser(null);
        deleteCookie();
      }
    };
    window.addEventListener("storage", storageChangeHandler);
    return () => {
      window.removeEventListener("storage", storageChangeHandler)
    };
  }, [setUser]);
};

const UserChangeListener = () => {
  useUserStorageChangeListener();
  return <></>;
};

const UserButton = () => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const { user, setUser } = useUser();

  if (user === null) {
    return null;
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    setAnchorEl(null);
    signOut();
    setUser(null);
    history.push("/");
  }

  return (
    <div>
      <Button
        color="inherit"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        {user.firstName}
      </Button>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={anchorEl !== null}
        onClose={handleClose}
      >
        <MenuItem onClick={() => {
          handleClose();
          history.push("/user/edit")
        }} >
          Update account
        </MenuItem>
        <Divider style={{ marginBottom: "0.8rem" }} />
        <MenuItem onClick={logout}>Sign out</MenuItem>
      </Menu>
    </div>
  );
}

type AppDrawerInputs = {
  drawerOpen: boolean,
  closeDrawer: () => void,
  showLogin: () => void
}

const AppDrawer = ({ drawerOpen, closeDrawer, showLogin }: AppDrawerInputs) => {
  const history = useHistory();
  const { user, setUser } = useUser();

  const logout = () => {
    signOut();
    setUser(null);
    history.push("/");
  }

  return (
    <Drawer open={drawerOpen} onClose={closeDrawer}>
      <div
        role="presentation"
        onClick={closeDrawer}
        onKeyDown={closeDrawer}
        style={{ width: 250 }}
      >
        {/* <Toolbar style={{ backgroundColor: "#012169" }}>
          <img
            src="/emorylogo.png"
            alt="Emory Heart and Vascular Center Logo"
            style={{
              height: "40px",
              width: "auto",
              marginRight: "1rem"
            }}
          />
        </Toolbar> */}
        <Box bgcolor="primary.main" height="120px" display="flex" alignItems="center" justifyContent="center">
          <img
            src="/emorylogo.png"
            alt="Emory Heart and Vascular Center Logo"
            style={{
              height: "80px",
              width: "auto",
            }}
          />
        </Box>
        <List>
          <ListItem button component={RouterLink} to="/">
            <ListItemIcon><PeopleIcon /></ListItemIcon>
            <ListItemText primary="Physicians" />
          </ListItem>
          <ListItem button component={RouterLink} to="/calendar">
            <ListItemIcon><CalendarTodayIcon /></ListItemIcon>
            <ListItemText primary="Calendar" />
          </ListItem>
        </List>
        <Divider />
        <List>
          {!user && (
            <ListItem>
              <Button
                color="secondary"
                variant="contained"
                fullWidth
                onClick={showLogin}
              >
                Sign in
              </Button>
            </ListItem>
          )}
          {user && (
            <>
              <ListItem button component={RouterLink} to="/user/edit">
                <ListItemIcon><PersonIcon /></ListItemIcon>
                <ListItemText primary="Edit profile" />
              </ListItem>
              <ListItem button onClick={logout}>
                <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                <ListItemText primary="Sign out" />
              </ListItem>
            </>
          )}
        </List>
      </div>
    </Drawer>
  );
}

export default App;
