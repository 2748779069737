import React from 'react';
import { TextField } from "formik-material-ui";
import { useHistory, useLocation } from "react-router-dom";
import { AuthenticateResponse, AuthenticateRequest } from '../models/api';
import { saveToken, useUser, getUser } from '../models/auth';
import { Formik, Form, Field } from "formik";
import { client, getRemoteErrors } from '../models/client';
import { Container, Card, CardContent, Typography, Button, MenuItem } from '@material-ui/core';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const LoginPage = () => {
    const history = useHistory();
    const { setUser } = useUser();
    const query = useQuery();
    const redirectUrl = query.get("returnurl") || "/";

    return (
        <Container style={{ maxWidth: "500px" }}>
            <Card>
                <CardContent>
                    <Formik<AuthenticateRequest & { generalError: string | null }>
                        initialValues={{
                            type: "Emory",
                            username: "",
                            password: "",
                            generalError: null
                        }}
                        onSubmit={async (values, { setSubmitting, setErrors }) => {
                            try {
                                const { token } = await client.post<AuthenticateResponse>("/user/authenticate", values).then(o => o.data);
                                if (!token) {
                                    throw new Error("Token respoinse missing");
                                }
                                saveToken(token);
                                setUser(getUser());
                                history.push(redirectUrl);
                            } catch (ex) {
                                if (ex.response?.data) {
                                    const errors = getRemoteErrors(ex.response.data);
                                    console.log(errors);
                                    setErrors(errors);
                                } else {
                                    setErrors({
                                        generalError: ex.message
                                    });
                                }
                                setSubmitting(false);
                            }
                        }}
                    >
                        {({ isSubmitting, errors }) => (
                            <Form
                                autoComplete="on"
                            >
                                <Typography variant="h5" gutterBottom>
                                    Login
                                </Typography>
                                {errors.generalError && <Typography variant="subtitle1" gutterBottom color="error">{errors.generalError}</Typography>}
                                <Field
                                    name="type"
                                    type="text"
                                    label="Account Provider"
                                    select
                                    margin="normal"
                                    component={TextField}
                                    fullWidth
                                >
                                    <MenuItem value="Emory">Emory</MenuItem>
                                    <MenuItem value="NonEmory">Not Emory</MenuItem>
                                </Field>
                                <Field
                                    name="username"
                                    label="Emory NetID"
                                    type="input"
                                    component={TextField}
                                    margin="normal"
                                    autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
                                    fullWidth
                                />
                                <Field
                                    name="password"
                                    label="Password"
                                    type="password"
                                    component={TextField}
                                    margin="normal"
                                    fullWidth
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    disabled={isSubmitting}
                                >
                                    Sign In
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </CardContent>
            </Card>
        </Container>
    );
}

export default LoginPage;