import React, { useState } from 'react';
import { TextField } from "formik-material-ui";
import { Typography, Button, Dialog, DialogContent, DialogTitle, DialogActions, Box, useTheme, useMediaQuery } from '@material-ui/core';
import AdminTheme from './AdminTheme';
import { Formik, Form, Field } from 'formik';
import { ResetPasswordRequest, usePostUserResetPassword } from '../models/api';
import { getRemoteErrors } from '../models/client';

type InputProps = {
    open: boolean,
    onClose: () => void
}

const ResetPasswordDialog = ({ open, onClose }: InputProps) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
    const [submitted, setSubmitted] = useState(false);
    const { mutate: requestPasswordReset } = usePostUserResetPassword({});

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullScreen={fullScreen}
            aria-labelledby="reset-dialog-title"
        >
            <DialogTitle id="reset-dialog-title" style={{ paddingBottom: "0" }}>Request password reset</DialogTitle>
            {submitted && (
                <>
                    <DialogContent>
                        <Box pt={2}>
                            <Typography>
                                Your password reset request has been received.  Please check your email for instructions to complete the process.
                            </Typography>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <AdminTheme>
                            <Button onClick={onClose} color="primary" variant="contained">
                                Close
                            </Button>
                        </AdminTheme>
                    </DialogActions>
                </>
            )}
            {!submitted && (
                <Formik<ResetPasswordRequest & { generalError: string | null }>
                    initialValues={{
                        email: "",
                        generalError: null
                    }}
                    onSubmit={async (values, { setSubmitting, setErrors }) => {
                        try {
                            await requestPasswordReset(values);
                            setSubmitted(true);
                        } catch (ex) {
                            if (ex.data) {
                                const errors = getRemoteErrors(ex.data);
                                console.log(errors);
                                setErrors(errors);
                            } else {
                                setErrors({
                                    generalError: ex.message
                                });
                            }
                            setSubmitting(false);
                        }
                    }}
                >
                    {({ isSubmitting, errors }) => (
                        <Form autoComplete="on">
                            <DialogContent 
                                style={{ 
                                    paddingTop: "0",
                                    minWidth: isLargeScreen ? "400px" : "inherit"
                                }}>
                                {errors.generalError && <Typography variant="subtitle1" gutterBottom color="error">{errors.generalError}</Typography>}
                                <Field
                                    name="email"
                                    label="Email address"
                                    component={TextField}
                                    margin="normal"
                                    fullWidth
                                />
                            </DialogContent>
                            <DialogActions>
                                <AdminTheme>
                                    <Button onClick={onClose} color="primary" disabled={isSubmitting}>
                                        Cancel
                            </Button>
                                </AdminTheme>
                                <Button type="submit" color="secondary" variant="contained" disabled={isSubmitting}>
                                    Send reset code
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            )}
        </Dialog>
    );
}

export default ResetPasswordDialog;