import React from "react";
import { makeStyles, Theme, createStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        errorroot: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: "2em",
            paddingBottom: "2em"
        },
    }),
);

type InputProps = {
    message: string;
}

export const ErrorScreen: React.FC<InputProps> = ({ message, children }) => {
    const classes = useStyles({});
    return (
        <div className={classes.errorroot}>
            <Typography variant="h6" gutterBottom>{message}</Typography>
            {children}
        </div>
    );
};