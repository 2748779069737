import React, { useState } from 'react';
import { Card, CardContent, Typography, Table, TableRow, TableCell, TableHead, TableBody, Box, IconButton, Container, Tooltip, Button } from '@material-ui/core';
import { useGetCalendarSchedule, PhysicianResult, useGetPhysicianId } from "../models/api";
import { useUser } from '../models/auth';
import moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Skeleton from '@material-ui/lab/Skeleton';
import LockIcon from '@material-ui/icons/Lock';
import OpneInBrowserIcon from '@material-ui/icons/OpenInBrowser'
import PhysicianDialog from '../components/PhysicianDialog';
import { ErrorScreen } from '../components/ErrorScreen';

const CalendarPage = () => {
    const { user } = useUser();
    const title = "Calendar";

    if (user) {
        return <Calendar title={title} />;
    }
    return <CallToLogin title={title} />;
}

type ComponentProps = {
    title: string
}

const CallToLogin = ({ title }: ComponentProps) => {
    return (
        <Container maxWidth="sm">
            <Card>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {title}
                    </Typography>
                    <Box component="div">
                        <Typography>
                            Registered physicians, please sign in to view the calendar.
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        </Container>
    )
}

const Calendar = ({ title }: ComponentProps) => {
    const defaultDate = moment().startOf("isoWeek").toDate();
    const [date, setDate] = useState(defaultDate); // useState(new Date("2020-02-17"));
    const [physician, setPhysician] = useState<PhysicianResult | null>(null);
    const { data: calendar, error, loading, refetch } = useGetCalendarSchedule({
        queryParams: {
            date: date.toISOString()
        }
    });

    const handleDateChange = (newDate: Date | null) => {
        if (newDate) {
            console.log(newDate);
            setDate(newDate);
        }
    };

    const makeSeq = (count: number) => {
        const result: number[] = [];
        for (let index = 0; index < count; index++) {
            result.push(index);
        }
        return result;
    };

    if (error) {
        return (
            <ErrorScreen message="There was an error loading the calendar information.">
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => refetch()}
                >
                    Retry
                </Button>
            </ErrorScreen>
        );
    }
    
    return (
        <Card>
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    {title}
                </Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog2"
                        label="Start date"
                        format="MM/dd/yyyy"
                        value={date}
                        disabled={loading}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
                <div style={{ width: "auto", overflowX: "scroll" }}>
                    <Table aria-label="On-call Calendar">
                        {!loading && calendar && (
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    {calendar.allDates.map((item) => (
                                        <TableCell key={item.date}>
                                            {moment(item.date).format("ddd, MMM D")}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                        )}
                        <TableBody>
                            {(loading || !calendar) && makeSeq(6).map(i => (
                                <TableRow key={i}>
                                    {makeSeq(8).map(j => (
                                        <TableCell key={j}>
                                            <Skeleton variant="text" />
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                            {!loading && calendar && calendar.tasks.map((task, taskIndex) => (
                                <TableRow key={taskIndex}>
                                    <TableCell
                                        style={{
                                            backgroundColor: task.backgroundColor ? `#${task.backgroundColor}` : "inherit",
                                            color: task.textColor ? `#${task.textColor}` : "inherit",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        {task.name}
                                    </TableCell>
                                    {task.dates.map(calendarDate => (
                                        <TableCell
                                            key={calendarDate.date}
                                            style={{
                                                backgroundColor: calendarDate.isWeekend ? "#dff0f5" : "rgb(245, 250, 245)"
                                            }}
                                        >
                                            {calendarDate.entries && calendarDate.entries.map((entry, entryIndex) => (
                                                <Box
                                                    key={entryIndex}
                                                    component="div"
                                                    display="flex"
                                                    alignItems="center"
                                                    style={{
                                                        backgroundColor: (calendarDate.isWeekend && entry.backgroundColor === "F5FAF5") ? "#dff0f5" : entry.backgroundColor ? `#${entry.backgroundColor}` : "inherit",
                                                        padding: "2px 4px",
                                                    }}
                                                >
                                                    {entry.name}
                                                    {entry.lock === 1 && (
                                                        <LockIcon fontSize="small" style={{ color: "rgb(246, 204, 69)" }} />
                                                    )}
                                                    {entry.directoryID && (
                                                        <OpenPhysicianDialogButton
                                                            physicianId={entry.directoryID}
                                                            onClick={(physicianProfile) => setPhysician(physicianProfile)}
                                                        />
                                                    )}
                                                </Box>
                                            ))}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </CardContent>
            <PhysicianDialog
                physician={physician}
                onClose={() => setPhysician(null)}
            />
        </Card>
    );
}

type OpenPhysicianDialogButtonProps = {
    physicianId: number,
    onClick: (physician: PhysicianResult) => void
};

const OpenPhysicianDialogButton = ({ physicianId, onClick }: OpenPhysicianDialogButtonProps) => {
    const { data: physician, error } = useGetPhysicianId({
        id: physicianId
    });

    if (error) {
        return null;
    }

    // added span as direct descendant of Tooltip because button can be disabled    
    return (
        <Tooltip title={physician === null ? "Loading" : `View ${physician.displayName}`}>
            <span>
                <IconButton
                    size="small"
                    aria-label="Open physician modal dialog"
                    disabled={physician === null}
                    onClick={() => {
                        if (physician) {
                            onClick(physician);
                        }
                    }}
                >
                    <OpneInBrowserIcon />
                </IconButton>
            </span>
        </Tooltip>
    );
};


export default CalendarPage;