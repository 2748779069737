import React from "react";
import { ThemeProvider, createMuiTheme } from '@material-ui/core';
import { red } from "@material-ui/core/colors";

const adminMenuTheme = createMuiTheme({
    palette: {
        primary: red,
    },
    typography: {
        button: {
            textTransform: "none"
        }
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: "0.8rem"
            }
        }
    }
});

const AdminTheme: React.FC = ({ children }) => {
    return (
        <ThemeProvider theme={adminMenuTheme}>
            {children}
        </ThemeProvider>
    );
}

export default AdminTheme;