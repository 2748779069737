export const isProduction = process.env.NODE_ENV === "production";

export const apiUrl = isProduction ? "https://ctsurgeryapp.emoryhealthcare.org" : "http://localhost:5000";

export const webUrl = isProduction ? "" : "";

export const cookiePath = isProduction ? "/" : "/";

export const contentUrl = (url: string) => `${apiUrl}${url}`;

export const pictureUrl = (imageID: number) => contentUrl(`/api/physician/image/${imageID}`);
