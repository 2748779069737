import React, { useState } from 'react';
import { Typography, Button, Dialog, DialogContent, DialogTitle, DialogActions, Box, Tabs, Tab, useTheme, useMediaQuery } from '@material-ui/core';
import AdminTheme from './AdminTheme';
import ApplyExternal from './ApplyExternal';
import ApplyEmory from './ApplyEmory';

type InputProps = {
    open: boolean,
    onClose: () => void
}

const ApplyDialog = ({ open, onClose }: InputProps) => {
    const [tab, setTab] = useState(0);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [submitted, setSubmitted] = useState(false);

    const onFormSubmit = () => setSubmitted(true);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullScreen={fullScreen}
            // disableBackdropClick={true}
            aria-labelledby="apply-dialog-title"
        >
            <Box
                bgcolor="primary.main"
                color="primary.contrastText"
            >
                <DialogTitle 
                    id="apply-dialog-title"
                    style={{
                        paddingLeft: "12px",
                        paddingBottom: "0"
                    }}
                >
                    Apply for access
                </DialogTitle>
                {!submitted && (
                    <Tabs value={tab} onChange={(e, value: number) => setTab(value)} aria-label="physician details tabs">
                        <Tab label="Non-Emory Physicians" {...a11yProps(0)} />
                        <Tab label="Emory Employees" {...a11yProps(1)} />
                    </Tabs>
                )}
                {/* This is the spacer where the tabs would have existed. */}
                {submitted && (
                    <Box p={1}></Box>
                )}
            </Box>
            {submitted && (
                <>
                    <DialogContent>
                        <Box pt={2}>
                            <Typography>
                                Your application has been submitted, and you will be notified by email when it is approved.
                            </Typography>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <AdminTheme>
                            <Button onClick={onClose} color="primary" variant="contained">
                                Close
                            </Button>
                        </AdminTheme>
                    </DialogActions>
                </>
            )}
            {!submitted && (
                <>
                    <TabPanel value={tab} index={0}>
                        <ApplyExternal
                            onSubmit={onFormSubmit}
                            onClose={onClose}
                        />
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        <ApplyEmory
                            onSubmit={onFormSubmit}
                            onClose={onClose}
                        />
                    </TabPanel>
                </>
            )}
        </Dialog>
    );
}

const a11yProps = (index: number) => {
    return {
        id: `apply-tab-${index}`,
        'aria-controls': `apply-tabpanel-${index}`,
    };
};

type TabPanelProps = {
    index: number;
    value: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ index, value, children, ...other }) => {
    return (
        <Box
            hidden={value !== index}
            role="tabpanel"
            id={`apply-tabpanel-${index}`}
            aria-labelledby={`apply-tab-${index}`}
            {...other}
        >
            {children}
        </Box>
    );
}

export default ApplyDialog;