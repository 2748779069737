import React, { useState, useEffect } from 'react';
import { useGetPhysicianDashboard, PhysicianResult } from '../models/api';
import { List, ListItemText, ListItem, Grid, Paper, ListItemIcon, Checkbox, ListSubheader, ListItemAvatar, Hidden, TextField, Box, Dialog, Slide, DialogContent, Button, Typography, IconButton, useTheme, useMediaQuery, Fab, AppBar, Toolbar } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { PhysicianAvatar } from '../components/PhysicianAvatar';
import { useUser } from '../models/auth';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import AdminTheme from '../components/AdminTheme';
import PhysicianDialog from '../components/PhysicianDialog';
import { ErrorScreen } from '../components/ErrorScreen';


const PhysicianDashboard = () => {
    const { user } = useUser();
    const { data: dashboardResult, error, refetch } = useGetPhysicianDashboard({
        queryParams: {
            z: user?.id
        }
    });
    const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
    const [selectedSpecialties, setSelectedSpecialties] = useState<string[]>([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [physicians, setPhysicians] = useState<PhysicianResult[] | null>(null);
    const [physician, setPhysician] = useState<PhysicianResult | null>(null);
    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only("xs"));

    useEffect(() => {
        const hasMatch = (input: string | null | undefined, lowerSearchString: string) => {
            return (input ?? "").toLowerCase().indexOf(lowerSearchString) > -1;
        };
        if (dashboardResult?.physicians) {
            let results = dashboardResult?.physicians ?? [];

            if (selectedLocations.length > 0) {
                results = results
                    .filter(o => o.locations.some(p => selectedLocations.some(loc => hasMatch(loc, p.label?.toLowerCase() ?? ""))));
            }

            if (selectedSpecialties.length > 0) {
                results = results
                    .filter(o => o.specialties.some(p => selectedSpecialties.some(spc => hasMatch(spc, p.label?.toLowerCase() ?? ""))));
            }

            if (searchTerm) {
                const term = searchTerm.toLowerCase();
                results = results
                    .filter(o => {
                        if (hasMatch(o.displayName, term)) {
                            return true;
                        }
                        if (hasMatch(o.title, term)) {
                            return true;
                        }
                        if (o.specialties.some(p => hasMatch(p.label, term))) {
                            return true;
                        }
                        if (o.locations.some(p => hasMatch(p.label, term))) {
                            return true;
                        }
                        if (o.emails.some(p => hasMatch(p.email, term))) {
                            return true;
                        }
                        return false;
                    });
            }
            setPhysicians(results);
        }
    }, [dashboardResult, searchTerm, selectedLocations, selectedSpecialties]);

    // if items refresh from server (due to auth change, etc), then refresh currently displayed physician in dialog
    // using dashboardResult instead of physicians because physicians could be a filtered list and if the
    // updated item no longer satifies the filter, then i con't want to close the dialog
    useEffect(() => {
        const items = dashboardResult?.physicians;
        if (items && items.length > 0 && physician !== null) {
            const _physician = items.find(o => o.id === physician.id);
            setPhysician(_physician ?? null);  // close the dialog if the physician is not in the updated list
        }
    }, [dashboardResult, physician]);
    

    const { locations, specialties  } = dashboardResult ?? {
        locations: null,
        specialties: null
    };

    if (error) {
        return (
            <ErrorScreen message="There was an error loading the physicians.">
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => refetch()}
                >
                    Retry
                </Button>
            </ErrorScreen>
        );
    }

    // if (loading || physicians === null) {
    //     return <PageLoading />;
    // }

    const handleLocationToggle = (value: string) => {
        const index = selectedLocations.indexOf(value);
        const _selectedLocations = [...selectedLocations];
        if (index === -1) {
            _selectedLocations.push(value);
        } else {
            _selectedLocations.splice(index, 1);
        }
        setSelectedLocations(_selectedLocations);
    };

    const handleSpecialtyToggle = (value: string) => {
        const index = selectedSpecialties.indexOf(value);
        const _selectedSpecialties = [...selectedSpecialties];
        if (index === -1) {
            _selectedSpecialties.push(value);
        } else {
            _selectedSpecialties.splice(index, 1);
        }
        setSelectedSpecialties(_selectedSpecialties);
    };

    return (
        <Box paddingBottom="70px">
            {isXs && (
                <AdminTheme>
                    <Fab
                        color="primary"
                        style={{
                            margin: 0,
                            position: "fixed",
                            top: "auto",
                            right: 20,
                            bottom: 20,
                            left: "auto",
                        }}
                        onClick={() => setShowFilterDialog(true)}
                    >
                        <SearchIcon />
                    </Fab>
                </AdminTheme>
            )}
            <Grid container spacing={isXs ? 0 : 3}>
                {!isXs && (
                    <Grid item xs={12} sm={4} lg={3}>
                        <FilterOptions
                            locations={locations}
                            specialties={specialties}
                            handleLocationToggle={handleLocationToggle}
                            handleSpecialtyToggle={handleSpecialtyToggle}
                            selectedLocations={selectedLocations}
                            selectedSpecialties={selectedSpecialties}
                        />
                    </Grid>
                )}
                <Grid item xs={12} sm={8} lg={9}>
                    <Paper>
                        <Box style={{ padding: "0.5rem 1rem 1rem 1rem" }}>
                            <TextField
                                label="Search"
                                fullWidth
                                value={searchTerm}
                                disabled={physicians === null}
                                onChange={(e) => setSearchTerm(e.target.value ?? "")}
                            // InputProps={{
                            //     startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                            // }}
                            />
                        </Box>
                        {physicians && physicians.length === 0 && (
                            <Typography style={{ margin: "4rem 1rem 3rem 1rem", textAlign: "center" }} variant="body1">
                                There are no physicians matching your search term <strong>{searchTerm} </strong>
                                {selectedLocations.length > 0 && (
                                    <span>
                                        at locations <strong>{selectedLocations.join(", ")} </strong>
                                    </span>
                                )}
                                {selectedSpecialties.length > 0 && (
                                    <span>
                                        with specialties <strong>{selectedSpecialties.join(", ")} </strong>
                                    </span>
                                )}
                            </Typography>
                        )}
                        <List>
                            {!physicians && (
                                <ListItem>
                                    <ListItemAvatar style={{ marginRight: "1rem" }}>
                                        <Skeleton variant="circle" width={72} height={72} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        disableTypography
                                        primary={<Skeleton variant="text" />}
                                        secondary={<Skeleton variant="text" />}
                                    />
                                </ListItem>
                            )}
                            {physicians && physicians.map((physician, index) => (
                                <ListItem
                                    key={physician.id}
                                    button
                                    onClick={() => setPhysician(physician)}
                                    divider={index !== (physicians.length - 1)}
                                >
                                    <ListItemAvatar style={{ marginRight: "1rem", alignSelf: "start" }}>
                                        <PhysicianAvatar
                                            imageID={physician.imageID}
                                            displayName={physician.displayName}
                                            avatarSize={72}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={physician.displayName}
                                        secondary={(
                                            <>
                                                {physician.title}
                                                <Hidden only={["xs", "sm"]}>
                                                    {physician.specialties.length > 0 && (
                                                        <span style={{ display: "block", marginTop: "0.5rem" }}>
                                                            <strong>Specialties:</strong> {physician.specialties.map(o => o.label).join(", ")}
                                                        </span>
                                                    )}
                                                    {physician.locations.length > 0 && (
                                                        <span style={{ display: "block", marginTop: "0.5rem" }}>
                                                            <strong>Locations:</strong> {physician.locations.map(o => o.label).join(", ")}
                                                        </span>
                                                    )}
                                                </Hidden>
                                            </>
                                        )}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                    <PhysicianDialog
                        physician={physician}
                        onClose={() => setPhysician(null)}
                    />
                </Grid>
            </Grid>
            {isXs && (
                <Dialog
                    fullScreen
                    open={showFilterDialog}
                    onClose={() => setShowFilterDialog(false)}
                    TransitionComponent={Transition}
                    aria-labelledby="filter-dialog-title"
                >
                    <AppBar position="relative">
                        <Toolbar>
                            <IconButton onClick={() => setShowFilterDialog(false)} style={{ marginRight: "12px" }}>
                                <CloseIcon style={{ color: "#ffffff" }} />
                            </IconButton>
                            <Typography variant="h6" id="filter-dialog-title">
                                Filter physicians
                            </Typography>
                            <Box display="flex" flexGrow="1" />
                            {(selectedLocations.length > 0 || selectedSpecialties.length > 0) && (
                                <Button
                                    color="inherit"
                                    onClick={() => {
                                        setSelectedLocations([]);
                                        setSelectedSpecialties([]);
                                    }}
                                >
                                    Clear all
                                </Button>
                            )}
                        </Toolbar>
                    </AppBar>
                    <DialogContent style={{ padding: "0" }}>
                        <FilterOptions
                            locations={locations}
                            specialties={specialties}
                            handleLocationToggle={handleLocationToggle}
                            handleSpecialtyToggle={handleSpecialtyToggle}
                            selectedLocations={selectedLocations}
                            selectedSpecialties={selectedSpecialties}
                        />
                    </DialogContent>
                </Dialog>
            )}
        </Box>
    );
}

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

type FilterOptionsInput = {
    locations: string[] | null,
    specialties: string[] | null,
    handleLocationToggle: (value: string) => void,
    handleSpecialtyToggle: (value: string) => void,
    selectedLocations: string[],
    selectedSpecialties: string[],
}


const FilterOptions = ({ locations, specialties, handleLocationToggle, handleSpecialtyToggle, selectedLocations, selectedSpecialties }: FilterOptionsInput) => {
    return (
        <>
            <Paper>
                <List
                    component="nav"
                    aria-labelledby="list-header-locations"
                    subheader={(
                        <ListSubheader component="div" id="list-header-locations">
                            Locations
                        </ListSubheader>
                    )}
                >
                    {!locations && (
                        <ListItem role={undefined} dense>
                            <ListItemIcon>
                                <Skeleton
                                    variant="rect"
                                    width={18}
                                    height={18}
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary={<Skeleton variant="text" />}
                            />
                        </ListItem>
                    )}
                    {locations && locations.map((item, index) => {
                        const labelId = `checkbox-list-location-${index}`;
                        return (
                            <ListItem key={index} role={undefined} dense button onClick={() => handleLocationToggle(item)}>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        color="primary"
                                        checked={selectedLocations.indexOf(item) !== -1}
                                        tabIndex={-1}
                                        inputProps={{ "aria-labelledby": labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    id={labelId}
                                    primary={item}
                                />
                            </ListItem>
                        );
                    })}
                </List>
            </Paper>
            <Paper style={{ marginTop: "1rem" }}>
                <List
                    component="nav"
                    aria-labelledby="list-header-specialties"
                    subheader={(
                        <ListSubheader component="div" id="list-header-specialties">
                            Specialties
                        </ListSubheader>
                    )}
                >
                    {!specialties && (
                        <ListItem role={undefined} dense>
                            <ListItemIcon>
                                <Skeleton
                                    variant="rect"
                                    width={18}
                                    height={18}
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary={<Skeleton variant="text" />}
                            />
                        </ListItem>
                    )}
                    {specialties && specialties.map((item, index) => {
                        const labelId = `checkbox-list-specialty-${index}`;
                        return (
                            <ListItem key={index} role={undefined} dense button onClick={() => handleSpecialtyToggle(item)}>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        color="primary"
                                        checked={selectedSpecialties.indexOf(item) !== -1}
                                        tabIndex={-1}
                                        inputProps={{ "aria-labelledby": labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    id={labelId}
                                    primary={item}
                                />
                            </ListItem>
                        );
                    })}
                </List>
            </Paper>
        </>
    );
};



export default PhysicianDashboard;