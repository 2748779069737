import React, { useState, useRef } from 'react';
import { usePostPhysicianAdmin, PhysicianEditRequest, PhysicianAdminResult, PhysicianEmail, PhysicianLocation, PhysicianPhone, PhysicianUrl, PhysicianSpecialty } from '../../models/api';
import { TextField } from "formik-material-ui";
import { Formik, Form, Field, FieldProps } from "formik";
import { getRemoteErrors, client } from '../../models/client';
import { FormHelperText, TextField as MuiTextField, FormControlLabel, Switch, Paper, Typography, Button, AppBar, Toolbar, IconButton, makeStyles, Tooltip, Grid, Avatar, Container, Slider, InputLabel } from '@material-ui/core';
import MaterialTable, { Column } from 'material-table';
import { useSnackbar } from 'notistack';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import { contentUrl } from '../../models/config';
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import PersonIcon from "@material-ui/icons/Person";

// there's a red dot at the top of images sometimes
// open issue:  https://github.com/mosch/react-avatar-editor/issues/311

type InputProps = {
    title: string,
    physician?: PhysicianAdminResult | null | undefined,
    onClose: () => void,
    onSave: () => Promise<void>
}

const useStyles = makeStyles(theme => ({
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    appBarOffset: theme.mixins.toolbar,
}));


const EditPhysician = ({ title, physician, onClose, onSave }: InputProps) => {
    const classes = useStyles();
    const { mutate: save } = usePostPhysicianAdmin({});
    const { enqueueSnackbar } = useSnackbar();
    const avatarEditor = useRef<AvatarEditor | null>(null);

    const initialFileValue = physician?.imageID ? contentUrl(`/api/physician/image/${physician.imageID}`) : null;
    const [file, setFile] = useState<File | string | null>(initialFileValue);
    const [scale, setScale] = useState(1);
    const [hasImageChanged, setHasImageChanged] = useState(false);
    const imageDimension = 250;
    const borderRadius = imageDimension / 2;

    const vm: PhysicianEditRequest = physician ??
        {
            id: 0,
            firstName: "",
            lastName: "",
            displayName: "",
            title: "",
            published: true,
            qgendaName: "",
            emails: [],
            locations: [],
            phones: [],
            specialties: [],
            urls: []
        };

    return (
        <Formik<PhysicianEditRequest>
            initialValues={vm}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
                try {
                    const { id } = await save(values);
                    if (hasImageChanged && avatarEditor.current) {
                        try {
                            const formData = new FormData();

                            //const canvas = avatarEditor.current.getImage();
                            const canvas = avatarEditor.current.getImageScaledToCanvas();
                            const dataUrl = canvas.toDataURL()
                            const blobBin = atob(dataUrl.split(',')[1]);
                            const array: number[] = [];
                            for (let i = 0; i < blobBin.length; i++) {
                                array.push(blobBin.charCodeAt(i));
                            }
                            const file = new Blob([new Uint8Array(array)], { type: "image/jpeg" });

                            formData.append("file", file);
                            await client.post(`/physician/admin/${id}/image`, formData);
                        } catch (fileErr) {
                            console.log(fileErr);
                            enqueueSnackbar(`There was an error uploading the image. ${fileErr.message}`, { variant: "error" });
                        }
                    }
                    await onSave();
                } catch (ex) {
                    console.log(ex);
                    if (ex.data) {
                        const errors = getRemoteErrors(ex.data);
                        setErrors(errors);
                        enqueueSnackbar("There was an error saving your changes.  Please review the form fields.", { variant: "error" });
                    } else {
                        enqueueSnackbar(`There was an error saving your changes. ${ex.message}`, { variant: "error" });
                    }
                    setSubmitting(false);
                }
            }}
        >
            {({ isSubmitting, submitForm }) => (
                <Form autoComplete="off">
                    <AppBar >
                        <Toolbar>
                            <Tooltip title="Close without saving.">
                                <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close" disabled={isSubmitting}>
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                            <Typography variant="h6" className={classes.title}>
                                {title}{isSubmitting ? " Saving..." : ""}
                            </Typography>
                            <Button
                                // type="submit"  not doing a submit button for the form because a user my press enter while editing a table value
                                color="inherit"
                                disabled={isSubmitting}
                                onClick={submitForm}
                                startIcon={<SaveIcon />}
                            >
                                Save physician
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <div className={classes.appBarOffset} />
                    <div style={{ flexGrow: 1, margin: "1rem 2rem" }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={8}>
                                <Field
                                    name="firstName"
                                    label="First Name"
                                    type="input"
                                    component={TextField}
                                    margin="normal"
                                    fullWidth
                                />
                                <Field
                                    name="lastName"
                                    label="Last Name"
                                    type="input"
                                    component={TextField}
                                    margin="normal"
                                    fullWidth
                                />
                                <Field
                                    name="displayName"
                                    label="Display Name"
                                    type="input"
                                    component={TextField}
                                    margin="normal"
                                    fullWidth
                                />
                                <Field
                                    name="title"
                                    label="Title"
                                    type="input"
                                    component={TextField}
                                    margin="normal"
                                    fullWidth
                                />
                                <Field
                                    name="qgendaName"
                                    label="Qgenda Name"
                                    type="input"
                                    component={TextField}
                                    margin="normal"
                                    placeholder="like Terpenning, Stillman, or De Cecco"
                                    fullWidth
                                />
                                <FormHelperText>Matching this physician to their Qgenda name allows users to get physician details from the calendar page.</FormHelperText>
                                <Field name="published">
                                    {({ field, form }: FieldProps<boolean>) => (
                                        <div style={{ marginTop: "1rem" }}>
                                            <FormControlLabel
                                                control={(
                                                    <Switch
                                                        color="secondary"
                                                        checked={field.value}
                                                        onChange={(e) => form.setFieldValue("published", e.target.checked)}
                                                    />
                                                )}
                                                label="Published"
                                            />
                                            <FormHelperText>If published, the physician will be visible in the directory.</FormHelperText>
                                        </div>
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Dropzone
                                    onDrop={(acceptedFiles: File[]) => {
                                        if (acceptedFiles.length === 0) {
                                            return;
                                        }
                                        setFile(acceptedFiles[0]);
                                    }}
                                    accept=".jpeg,.jpg"
                                    noClick
                                    //noClick={file !== null}
                                    noKeyboard
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <Container {...getRootProps()}>
                                            <Typography variant="subtitle2" align="center">
                                                Drop an image to add or replace
                                            </Typography>
                                            <div style={{ width: imageDimension + 2, marginLeft: "auto", marginRight: "auto" }}>
                                                {file && (
                                                    <>
                                                        <AvatarEditor
                                                            ref={(instance) => avatarEditor.current = instance}
                                                            width={imageDimension}
                                                            height={imageDimension}
                                                            image={file}
                                                            scale={scale}
                                                            border={0}
                                                            crossOrigin="anonymous"
                                                            borderRadius={borderRadius}
                                                            style={{
                                                                border: "1px solid black"
                                                            }}
                                                            onImageChange={() => { 
                                                                if (!hasImageChanged) {
                                                                    setHasImageChanged(true);
                                                                }
                                                            }}
                                                            //onLoadSuccess={() => console.log("on load success")}
                                                            //onPositionChange={() => console.log("on position change")}
                                                        />
                                                        <InputLabel style={{ marginTop: "1rem" }}>Zoom</InputLabel>
                                                        <Slider
                                                            value={scale}
                                                            min={1}
                                                            max={10}
                                                            step={0.1}
                                                            onChange={(e, value) => {
                                                                var singlularValue = value as number;
                                                                if (singlularValue !== null) {
                                                                    setScale(singlularValue);
                                                                }
                                                            }}
                                                        />
                                                    </>
                                                )}
                                                {!file && (
                                                    <Avatar
                                                        variant="square"

                                                        style={{
                                                            width: imageDimension,
                                                            height: imageDimension
                                                        }}>
                                                        <PersonIcon style={{ fontSize: imageDimension }} />
                                                    </Avatar>
                                                )}
                                            </div>
                                            <input {...getInputProps()} />
                                        </Container>
                                    )}
                                </Dropzone>
                            </Grid>
                            <Grid item xs={12}>
                                <Field name="emails">
                                    {(fieldProps: FieldProps<PhysicianEmail[]>) => (
                                        <PropertyTable<PhysicianEmail>
                                            title="Emails"
                                            fieldProps={fieldProps}
                                            columns={[
                                                {
                                                    field: "email",
                                                    title: "Email",
                                                    initialEditValue: "",
                                                    editComponent: (props) => (
                                                        <MuiTextField
                                                            value={props.value ?? ""}
                                                            onChange={e => props.onChange(e.target.value)}
                                                            fullWidth
                                                        />)
                                                },
                                                {
                                                    field: "isPublic",
                                                    title: "Public",
                                                    initialEditValue: true,
                                                    lookup: {
                                                        true: "Yes",
                                                        false: "No"
                                                    }
                                                }
                                            ]}
                                        />
                                    )}
                                </Field>
                                <Field name="phones">
                                    {(fieldProps: FieldProps<PhysicianPhone[]>) => (
                                        <PropertyTable<PhysicianPhone>
                                            title="Phones"
                                            fieldProps={fieldProps}
                                            columns={[
                                                {
                                                    field: "label",
                                                    title: "Label",
                                                    initialEditValue: "",
                                                    editComponent: (props) => (
                                                        <MuiTextField
                                                            value={props.value ?? ""}
                                                            onChange={e => props.onChange(e.target.value)}
                                                            placeholder="ex: Office, Mobile, Fax, etc"
                                                            fullWidth
                                                        />)
                                                },
                                                {
                                                    field: "number",
                                                    title: "Number",
                                                    initialEditValue: "",
                                                    editComponent: (props) => (
                                                        <MuiTextField
                                                            value={props.value ?? ""}
                                                            onChange={e => props.onChange(e.target.value)}
                                                            placeholder="(555) 555-5555"
                                                            fullWidth
                                                        />)
                                                },
                                                {
                                                    field: "isPublic",
                                                    title: "Public",
                                                    initialEditValue: true,
                                                    lookup: {
                                                        true: "Yes",
                                                        false: "No"
                                                    }
                                                }
                                            ]}
                                        />
                                    )}
                                </Field>
                                <Field name="urls">
                                    {(fieldProps: FieldProps<PhysicianUrl[]>) => (
                                        <PropertyTable<PhysicianUrl>
                                            title="URLs"
                                            fieldProps={fieldProps}
                                            columns={[
                                                {
                                                    field: "label",
                                                    title: "Label",
                                                    initialEditValue: "",
                                                    editComponent: (props) => (
                                                        <MuiTextField
                                                            value={props.value ?? ""}
                                                            onChange={e => props.onChange(e.target.value)}
                                                            placeholder="ex: Emory Profile"
                                                            fullWidth
                                                        />)
                                                },
                                                {
                                                    field: "url",
                                                    title: "URL",
                                                    initialEditValue: "",
                                                    editComponent: (props) => (
                                                        <MuiTextField
                                                            value={props.value ?? ""}
                                                            onChange={e => props.onChange(e.target.value)}
                                                            placeholder="https://www.emoryhealthcare.org/"
                                                            fullWidth
                                                        />)
                                                },
                                                {
                                                    field: "isPublic",
                                                    title: "Public",
                                                    initialEditValue: true,
                                                    lookup: {
                                                        true: "Yes",
                                                        false: "No"
                                                    }
                                                }
                                            ]}
                                        />
                                    )}
                                </Field>
                                <Field name="specialties">
                                    {(fieldProps: FieldProps<PhysicianSpecialty[]>) => (
                                        <PropertyTable<PhysicianSpecialty>
                                            title="Specialties"
                                            fieldProps={fieldProps}
                                            columns={[
                                                {
                                                    field: "label",
                                                    title: "Name",
                                                    editComponent: (props) => (
                                                        <MuiTextField
                                                            value={props.value ?? ""}
                                                            onChange={e => props.onChange(e.target.value)}
                                                            placeholder="ex: Robotic Surgery"
                                                            fullWidth
                                                        />)
                                                }
                                            ]}
                                        />
                                    )}
                                </Field>
                                <Field name="locations">
                                    {(fieldProps: FieldProps<PhysicianLocation[]>) => (
                                        <PropertyTable<PhysicianLocation>
                                            title="Locations"
                                            fieldProps={fieldProps}
                                            columns={[
                                                {
                                                    field: "label",
                                                    title: "Name",
                                                    editComponent: (props) => (
                                                        <MuiTextField
                                                            value={props.value ?? ""}
                                                            onChange={e => props.onChange(e.target.value)}
                                                            fullWidth
                                                        />)
                                                }
                                            ]}
                                        />
                                    )}
                                </Field>
                            </Grid>
                        </Grid>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

interface PropertyTable<RowData extends object> {
    title: string,
    columns: Column<RowData>[],
    fieldProps: FieldProps<RowData[]>
};

function PropertyTable<RowData extends object>({ title, columns, fieldProps }: PropertyTable<RowData>) {
    const { field, form } = fieldProps;
    return (
        <div style={{ maxWidth: "100%" }}>
            <MaterialTable
                title={title}
                data={field.value ?? []}
                style={{
                    marginBottom: "1.8rem"
                }}
                options={{
                    paging: false,
                    search: false
                }}
                components={{
                    Container: (props: any) => <Paper {...props} elevation={0} />
                }}
                editable={{
                    onRowAdd: async (newData) => {
                        const _items = [
                            ...field.value,
                            newData
                        ];
                        form.setFieldValue(field.name, _items);
                    },
                    onRowUpdate: async (newData, oldData) => {
                        try {
                            if (!oldData) {
                                throw new Error("Could not read old data.");
                            }
                            const _items = [...field.value];
                            const index = _items.indexOf(oldData);
                            if (index === -1) {
                                throw new Error("Could not find index of old data.");
                            }
                            _items[index] = newData;
                            form.setFieldValue(field.name, _items);
                        } catch (err) {
                            console.log(err.message);
                            throw err;
                        }
                    },
                    onRowDelete: async (oldData) => {
                        const index = field.value.indexOf(oldData);
                        const _items = field.value.filter((o, i) => i !== index);
                        form.setFieldValue(field.name, _items);
                    }
                }}
                actions={[
                    {
                        icon: "keyboard_arrow_up",
                        tooltip: "Move up",
                        onClick: (e, data) => {
                            const item = data as RowData;
                            if (!item) {
                                return;
                            }
                            const _items = [...field.value];
                            const index = _items.indexOf(item);
                            if (index < 1) {
                                return;
                            }
                            _items.splice(index, 1);
                            _items.splice(index - 1, 0, item);
                            form.setFieldValue(field.name, _items);
                        }
                    },
                    {
                        icon: "keyboard_arrow_down",
                        tooltip: "Move down",
                        onClick: (e, data) => {
                            const item = data as RowData;
                            if (!item) {
                                return;
                            }
                            const _items = [...field.value];
                            const index = _items.indexOf(item);
                            if (index === -1 || index === (_items.length - 1)) {
                                return;
                            }
                            _items.splice(index, 1);
                            _items.splice(index + 1, 0, item);
                            form.setFieldValue(field.name, _items);
                        }
                    }
                ]}
                localization={{
                    header: {
                        actions: ""
                    }
                }}
                columns={columns}
            />
        </div>
    );
};


// const ImageEditor = () => {
//     const [file, setFile] = useState<File | string | null>("/logo512.png");
//     const avatarEditor = useRef<AvatarEditor | null>(null);
//     const imageDimension = 250;
//     const borderRadius = imageDimension / 2;
//     const [scale, setScale] = useState(1);

//     const save = () => {
//         if (avatarEditor.current === null) {
//             return;
//         }
//         //const canvas = avatarEditor.current.getImageScaledToCanvas();
//         const canvas = avatarEditor.current.getImage();
//         const dataUrl = canvas.toDataURL()
//         const blobBin = atob(dataUrl.split(',')[1]);
//         const array: number[] = [];
//         for (let i = 0; i < blobBin.length; i++) {
//             array.push(blobBin.charCodeAt(i));
//         }
//         const file = new Blob([new Uint8Array(array)], { type: "image/jpeg" });
//         return file;
//     }

// };


export default EditPhysician;