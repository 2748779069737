/* Generated by restful-react */

import React from "react";
import {
  Get,
  GetProps,
  useGet,
  UseGetProps,
  Mutate,
  MutateProps,
  useMutate,
  UseMutateProps
} from "restful-react";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export interface CalendarEntry {
  name: string;
  textColor: string;
  backgroundColor: string;
  order: number;
  lock: number;
  directoryID?: number | null;
}

export interface CalendarDate {
  date: string;
  isWeekend: boolean;
  entries?: CalendarEntry[] | null;
}

export interface CalendarTask {
  name: string;
  textColor: string;
  backgroundColor: string;
  dates: CalendarDate[];
}

export interface CalendarTasksResponse {
  tasks: CalendarTask[];
  allDates: CalendarDate[];
}

export interface PhysicianPhone {
  label?: string | null;
  number?: string | null;
  isPublic?: boolean;
}

export interface PhysicianEmail {
  email?: string | null;
  isPublic?: boolean;
}

export interface PhysicianUrl {
  label?: string | null;
  url?: string | null;
  isPublic?: boolean;
}

export interface PhysicianSpecialty {
  label?: string | null;
}

export interface PhysicianLocation {
  label?: string | null;
}

export interface PhysicianResult {
  id: number;
  firstName: string;
  lastName: string;
  displayName: string;
  title: string;
  imageID?: number | null;
  phones: PhysicianPhone[];
  emails: PhysicianEmail[];
  urls: PhysicianUrl[];
  specialties: PhysicianSpecialty[];
  locations: PhysicianLocation[];
}

export interface PhysicianDashboardResult {
  physicians: PhysicianResult[];
  specialties: string[];
  locations: string[];
}

export interface PhysicianAdminResult {
  id: number;
  firstName: string;
  lastName: string;
  displayName: string;
  title: string;
  published: boolean;
  qgendaName?: string | null;
  imageID?: number | null;
  phones: PhysicianPhone[];
  emails: PhysicianEmail[];
  urls: PhysicianUrl[];
  specialties: PhysicianSpecialty[];
  locations: PhysicianLocation[];
}

export interface PhysicianEditRequest {
  id: number;
  firstName: string;
  lastName: string;
  displayName: string;
  title: string;
  published: boolean;
  qgendaName?: string | null;
  phones: PhysicianPhone[];
  emails: PhysicianEmail[];
  urls: PhysicianUrl[];
  specialties: PhysicianSpecialty[];
  locations: PhysicianLocation[];
}

export interface IdResponse {
  id: number;
}

export type UserType = "Emory" | "NonEmory";

export interface AuthenticateRequest {
  type: UserType;
  username: string;
  password: string;
}

export interface AuthenticateResponse {
  token: string;
}

export interface ApplyExternalRequest {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  company: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  phoneType: string;
  number: string;
}

export interface ApplyEmoryRequest {
  username: string;
  password: string;
}

export interface ResetPasswordRequest {
  email: string;
}

export interface ResetPasswordUpdateRequest {
  code: string;
  password: string;
}

export interface UserAddressViewModel {
  street: string;
  city: string;
  state: string;
  zip: string;
}

export interface UserPhoneViewModel {
  type: string;
  number: string;
}

export interface UserViewModel {
  type: UserType;
  firstName: string;
  lastName: string;
  email: string;
  company: string;
  addresses: UserAddressViewModel[];
  phones: UserPhoneViewModel[];
  password?: string | null;
  confirmPassword?: string | null;
}

export interface UserSearchRequest {
  input: string | null;
}

export interface PersonViewModel {
  directoryKey: string;
  name: string;
  email: string;
  title?: string | null;
  department?: string | null;
  phone?: string | null;
}

export type UserApprovalState = "Unknown" | "Approved" | "Denied";

export interface UserListItemAdmin {
  id: number;
  type: UserType;
  name?: string | null;
  email?: string | null;
  isAdmin: boolean;
  approvalState: UserApprovalState;
  company?: string | null;
}

export interface UpdateUserListItemAdmin {
  id: number;
  isAdmin: boolean;
  approvalState: UserApprovalState;
}

export interface AddEmoryUserRequest {
  directoryKey: string;
}

export interface GetCalendarScheduleQueryParams {
  date?: string;
}

export type GetCalendarScheduleProps = Omit<
  GetProps<CalendarTasksResponse, unknown, GetCalendarScheduleQueryParams>,
  "path"
>;

export const GetCalendarSchedule = (props: GetCalendarScheduleProps) => (
  <Get<CalendarTasksResponse, unknown, GetCalendarScheduleQueryParams>
    path={`/api/Calendar/schedule`}
    {...props}
  />
);

export type UseGetCalendarScheduleProps = Omit<
  UseGetProps<CalendarTasksResponse, GetCalendarScheduleQueryParams>,
  "path"
>;

export const useGetCalendarSchedule = (props: UseGetCalendarScheduleProps) =>
  useGet<CalendarTasksResponse, unknown, GetCalendarScheduleQueryParams>(
    `/api/Calendar/schedule`,
    props
  );

export type GetPhysicianProps = Omit<
  GetProps<PhysicianResult[], unknown, void>,
  "path"
>;

export const GetPhysician = (props: GetPhysicianProps) => (
  <Get<PhysicianResult[], unknown, void> path={`/api/Physician`} {...props} />
);

export type UseGetPhysicianProps = Omit<
  UseGetProps<PhysicianResult[], void>,
  "path"
>;

export const useGetPhysician = (props: UseGetPhysicianProps) =>
  useGet<PhysicianResult[], unknown, void>(`/api/Physician`, props);

export interface GetPhysicianDashboardQueryParams {
  z?: string;
}

export type GetPhysicianDashboardProps = Omit<
  GetProps<PhysicianDashboardResult, unknown, GetPhysicianDashboardQueryParams>,
  "path"
>;

export const GetPhysicianDashboard = (props: GetPhysicianDashboardProps) => (
  <Get<PhysicianDashboardResult, unknown, GetPhysicianDashboardQueryParams>
    path={`/api/Physician/dashboard`}
    {...props}
  />
);

export type UseGetPhysicianDashboardProps = Omit<
  UseGetProps<PhysicianDashboardResult, GetPhysicianDashboardQueryParams>,
  "path"
>;

export const useGetPhysicianDashboard = (
  props: UseGetPhysicianDashboardProps
) =>
  useGet<PhysicianDashboardResult, unknown, GetPhysicianDashboardQueryParams>(
    `/api/Physician/dashboard`,
    props
  );

export type GetPhysicianIdProps = Omit<
  GetProps<PhysicianResult, unknown, void>,
  "path"
> & { id: number };

export const GetPhysicianId = ({ id, ...props }: GetPhysicianIdProps) => (
  <Get<PhysicianResult, unknown, void>
    path={`/api/Physician/${id}`}
    {...props}
  />
);

export type UseGetPhysicianIdProps = Omit<
  UseGetProps<PhysicianResult, void>,
  "path"
> & { id: number };

export const useGetPhysicianId = ({ id, ...props }: UseGetPhysicianIdProps) =>
  useGet<PhysicianResult, unknown, void>(`/api/Physician/${id}`, props);

export type GetPhysicianAdminProps = Omit<
  GetProps<PhysicianAdminResult[], unknown, void>,
  "path"
>;

export const GetPhysicianAdmin = (props: GetPhysicianAdminProps) => (
  <Get<PhysicianAdminResult[], unknown, void>
    path={`/api/Physician/admin`}
    {...props}
  />
);

export type UseGetPhysicianAdminProps = Omit<
  UseGetProps<PhysicianAdminResult[], void>,
  "path"
>;

export const useGetPhysicianAdmin = (props: UseGetPhysicianAdminProps) =>
  useGet<PhysicianAdminResult[], unknown, void>(`/api/Physician/admin`, props);

export type PostPhysicianAdminProps = Omit<
  MutateProps<IdResponse, unknown, void, PhysicianEditRequest>,
  "path" | "verb"
>;

export const PostPhysicianAdmin = (props: PostPhysicianAdminProps) => (
  <Mutate<IdResponse, unknown, void, PhysicianEditRequest>
    verb="POST"
    path={`/api/Physician/admin`}
    {...props}
  />
);

export type UsePostPhysicianAdminProps = Omit<
  UseMutateProps<IdResponse, void, PhysicianEditRequest>,
  "path" | "verb"
>;

export const usePostPhysicianAdmin = (props: UsePostPhysicianAdminProps) =>
  useMutate<IdResponse, unknown, void, PhysicianEditRequest>(
    "POST",
    `/api/Physician/admin`,
    props
  );

export type PostPhysicianAdminIdImageProps = Omit<
  MutateProps<void, unknown, void, void>,
  "path" | "verb"
> & { id: number };

export const PostPhysicianAdminIdImage = ({
  id,
  ...props
}: PostPhysicianAdminIdImageProps) => (
  <Mutate<void, unknown, void, void>
    verb="POST"
    path={`/api/Physician/admin/${id}/image`}
    {...props}
  />
);

export type UsePostPhysicianAdminIdImageProps = Omit<
  UseMutateProps<void, void, void>,
  "path" | "verb"
> & { id: number };

export const usePostPhysicianAdminIdImage = ({
  id,
  ...props
}: UsePostPhysicianAdminIdImageProps) =>
  useMutate<void, unknown, void, void>(
    "POST",
    `/api/Physician/admin/${id}/image`,
    props
  );

export type GetPhysicianImageIdProps = Omit<
  GetProps<void, unknown, void>,
  "path"
> & { id: number };

export const GetPhysicianImageId = ({
  id,
  ...props
}: GetPhysicianImageIdProps) => (
  <Get<void, unknown, void> path={`/api/Physician/image/${id}`} {...props} />
);

export type UseGetPhysicianImageIdProps = Omit<
  UseGetProps<void, void>,
  "path"
> & { id: number };

export const useGetPhysicianImageId = ({
  id,
  ...props
}: UseGetPhysicianImageIdProps) =>
  useGet<void, unknown, void>(`/api/Physician/image/${id}`, props);

export type PostUserAuthenticateProps = Omit<
  MutateProps<AuthenticateResponse, unknown, void, AuthenticateRequest>,
  "path" | "verb"
>;

export const PostUserAuthenticate = (props: PostUserAuthenticateProps) => (
  <Mutate<AuthenticateResponse, unknown, void, AuthenticateRequest>
    verb="POST"
    path={`/api/User/authenticate`}
    {...props}
  />
);

export type UsePostUserAuthenticateProps = Omit<
  UseMutateProps<AuthenticateResponse, void, AuthenticateRequest>,
  "path" | "verb"
>;

export const usePostUserAuthenticate = (props: UsePostUserAuthenticateProps) =>
  useMutate<AuthenticateResponse, unknown, void, AuthenticateRequest>(
    "POST",
    `/api/User/authenticate`,
    props
  );

export type PostUserApplyExternalProps = Omit<
  MutateProps<void, unknown, void, ApplyExternalRequest>,
  "path" | "verb"
>;

export const PostUserApplyExternal = (props: PostUserApplyExternalProps) => (
  <Mutate<void, unknown, void, ApplyExternalRequest>
    verb="POST"
    path={`/api/User/apply/external`}
    {...props}
  />
);

export type UsePostUserApplyExternalProps = Omit<
  UseMutateProps<void, void, ApplyExternalRequest>,
  "path" | "verb"
>;

export const usePostUserApplyExternal = (
  props: UsePostUserApplyExternalProps
) =>
  useMutate<void, unknown, void, ApplyExternalRequest>(
    "POST",
    `/api/User/apply/external`,
    props
  );

export type PostUserApplyEmoryProps = Omit<
  MutateProps<void, unknown, void, ApplyEmoryRequest>,
  "path" | "verb"
>;

export const PostUserApplyEmory = (props: PostUserApplyEmoryProps) => (
  <Mutate<void, unknown, void, ApplyEmoryRequest>
    verb="POST"
    path={`/api/User/apply/emory`}
    {...props}
  />
);

export type UsePostUserApplyEmoryProps = Omit<
  UseMutateProps<void, void, ApplyEmoryRequest>,
  "path" | "verb"
>;

export const usePostUserApplyEmory = (props: UsePostUserApplyEmoryProps) =>
  useMutate<void, unknown, void, ApplyEmoryRequest>(
    "POST",
    `/api/User/apply/emory`,
    props
  );

export type PostUserResetPasswordProps = Omit<
  MutateProps<void, unknown, void, ResetPasswordRequest>,
  "path" | "verb"
>;

export const PostUserResetPassword = (props: PostUserResetPasswordProps) => (
  <Mutate<void, unknown, void, ResetPasswordRequest>
    verb="POST"
    path={`/api/User/resetPassword`}
    {...props}
  />
);

export type UsePostUserResetPasswordProps = Omit<
  UseMutateProps<void, void, ResetPasswordRequest>,
  "path" | "verb"
>;

export const usePostUserResetPassword = (
  props: UsePostUserResetPasswordProps
) =>
  useMutate<void, unknown, void, ResetPasswordRequest>(
    "POST",
    `/api/User/resetPassword`,
    props
  );

export type PostUserResetPasswordUpdateProps = Omit<
  MutateProps<void, unknown, void, ResetPasswordUpdateRequest>,
  "path" | "verb"
>;

export const PostUserResetPasswordUpdate = (
  props: PostUserResetPasswordUpdateProps
) => (
  <Mutate<void, unknown, void, ResetPasswordUpdateRequest>
    verb="POST"
    path={`/api/User/resetPasswordUpdate`}
    {...props}
  />
);

export type UsePostUserResetPasswordUpdateProps = Omit<
  UseMutateProps<void, void, ResetPasswordUpdateRequest>,
  "path" | "verb"
>;

export const usePostUserResetPasswordUpdate = (
  props: UsePostUserResetPasswordUpdateProps
) =>
  useMutate<void, unknown, void, ResetPasswordUpdateRequest>(
    "POST",
    `/api/User/resetPasswordUpdate`,
    props
  );

export type GetUserEditProps = Omit<
  GetProps<UserViewModel, unknown, void>,
  "path"
>;

export const GetUserEdit = (props: GetUserEditProps) => (
  <Get<UserViewModel, unknown, void> path={`/api/User/edit`} {...props} />
);

export type UseGetUserEditProps = Omit<
  UseGetProps<UserViewModel, void>,
  "path"
>;

export const useGetUserEdit = (props: UseGetUserEditProps) =>
  useGet<UserViewModel, unknown, void>(`/api/User/edit`, props);

export type PostUserEditProps = Omit<
  MutateProps<AuthenticateResponse, unknown, void, UserViewModel>,
  "path" | "verb"
>;

export const PostUserEdit = (props: PostUserEditProps) => (
  <Mutate<AuthenticateResponse, unknown, void, UserViewModel>
    verb="POST"
    path={`/api/User/edit`}
    {...props}
  />
);

export type UsePostUserEditProps = Omit<
  UseMutateProps<AuthenticateResponse, void, UserViewModel>,
  "path" | "verb"
>;

export const usePostUserEdit = (props: UsePostUserEditProps) =>
  useMutate<AuthenticateResponse, unknown, void, UserViewModel>(
    "POST",
    `/api/User/edit`,
    props
  );

export type PostUserSearchEmoryProps = Omit<
  MutateProps<PersonViewModel[], unknown, void, UserSearchRequest>,
  "path" | "verb"
>;

export const PostUserSearchEmory = (props: PostUserSearchEmoryProps) => (
  <Mutate<PersonViewModel[], unknown, void, UserSearchRequest>
    verb="POST"
    path={`/api/User/search/emory`}
    {...props}
  />
);

export type UsePostUserSearchEmoryProps = Omit<
  UseMutateProps<PersonViewModel[], void, UserSearchRequest>,
  "path" | "verb"
>;

export const usePostUserSearchEmory = (props: UsePostUserSearchEmoryProps) =>
  useMutate<PersonViewModel[], unknown, void, UserSearchRequest>(
    "POST",
    `/api/User/search/emory`,
    props
  );

export type GetUserAdminProps = Omit<
  GetProps<UserListItemAdmin[], unknown, void>,
  "path"
>;

export const GetUserAdmin = (props: GetUserAdminProps) => (
  <Get<UserListItemAdmin[], unknown, void>
    path={`/api/User/admin`}
    {...props}
  />
);

export type UseGetUserAdminProps = Omit<
  UseGetProps<UserListItemAdmin[], void>,
  "path"
>;

export const useGetUserAdmin = (props: UseGetUserAdminProps) =>
  useGet<UserListItemAdmin[], unknown, void>(`/api/User/admin`, props);

export type PostUserAdminProps = Omit<
  MutateProps<void, unknown, void, UpdateUserListItemAdmin>,
  "path" | "verb"
>;

export const PostUserAdmin = (props: PostUserAdminProps) => (
  <Mutate<void, unknown, void, UpdateUserListItemAdmin>
    verb="POST"
    path={`/api/User/admin`}
    {...props}
  />
);

export type UsePostUserAdminProps = Omit<
  UseMutateProps<void, void, UpdateUserListItemAdmin>,
  "path" | "verb"
>;

export const usePostUserAdmin = (props: UsePostUserAdminProps) =>
  useMutate<void, unknown, void, UpdateUserListItemAdmin>(
    "POST",
    `/api/User/admin`,
    props
  );

export type PostUserAdminAddEmoryProps = Omit<
  MutateProps<void, unknown, void, AddEmoryUserRequest>,
  "path" | "verb"
>;

export const PostUserAdminAddEmory = (props: PostUserAdminAddEmoryProps) => (
  <Mutate<void, unknown, void, AddEmoryUserRequest>
    verb="POST"
    path={`/api/User/admin/addEmory`}
    {...props}
  />
);

export type UsePostUserAdminAddEmoryProps = Omit<
  UseMutateProps<void, void, AddEmoryUserRequest>,
  "path" | "verb"
>;

export const usePostUserAdminAddEmory = (
  props: UsePostUserAdminAddEmoryProps
) =>
  useMutate<void, unknown, void, AddEmoryUserRequest>(
    "POST",
    `/api/User/admin/addEmory`,
    props
  );

export type GetUserSeedProps = Omit<GetProps<void, unknown, void>, "path">;

export const GetUserSeed = (props: GetUserSeedProps) => (
  <Get<void, unknown, void> path={`/api/User/seed`} {...props} />
);

export type UseGetUserSeedProps = Omit<UseGetProps<void, void>, "path">;

export const useGetUserSeed = (props: UseGetUserSeedProps) =>
  useGet<void, unknown, void>(`/api/User/seed`, props);
