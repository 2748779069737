import jwtDecode from "jwt-decode";
import { SessionUser } from "./SessionUser";
import moment from "moment";
import React, { createContext, SetStateAction, Dispatch, useState, useContext } from "react";
import Cookies from "universal-cookie";
import { cookiePath, isProduction } from "./config";

const cookies = new Cookies();

export const tokenKey = "ctsurgeryapp_token";
const cookieName = "ctsurgeryapp";  // this must match the cookie name configured in on the server

export const retrieveToken = () => 
    localStorage.getItem(tokenKey);

export const saveToken = (token: string) => {
    localStorage.setItem(tokenKey, token);
    const user = parseToken(token);
    cookies.set(cookieName, token, {
        path: cookiePath,
        secure: isProduction ? true : undefined,
        expires: user?.getExpireDate()
    })
}

export const deleteCookie = () =>
    cookies.remove(cookieName);

export const signOut = () => {
    localStorage.clear();
    deleteCookie();
}

export const getUser = () => {
    const token = retrieveToken();
    if (token === null) {
        return null;
    }
    try {
        return parseToken(token);
    } catch (ex) {
        console.log("Unable to parse token");
        console.log(ex);
        return null;
    }
}

export const UserContext = createContext<{
    user: SessionUser | null,
    setUser: Dispatch<SetStateAction<SessionUser | null>>
}>({
    user: null,
    setUser: () => { }
});

export const UserProvider: React.FC = ({ children }) => {
    const [user, setUser] = useState<SessionUser | null>(getUser());
    const value = { 
        user, 
        setUser
    };
    return (
        <UserContext.Provider
            value={value}
            children={children}
        />
    );
}

export const UserConsumer = UserContext.Consumer;


export const useUser = () => useContext(UserContext);


type EmoryToken = {
    exp: number;
    given_name: string;
    family_name: string;
    unique_name: string;
    email: string;
    nameid: string;
    Title: string | null;
    role?: string[] | undefined | null;
    UserID: string
}

const parseToken = (token: string) => {
    const jwt = jwtDecode<EmoryToken>(token);

    if (isTokenExpired(jwt)) {
        console.log("token expired");
        return null;
    }

    const isAdmin = (jwt.role && jwt.role.includes("Admin")) || false;

    const user: SessionUser = {
        id: jwt.UserID,
        firstName: jwt.given_name,
        lastName: jwt.family_name,
        fullName: jwt.unique_name,
        email: jwt.email,
        directoryKey: jwt.nameid,
        title: jwt.Title,
        isAdmin: isAdmin,
        isExpired: function () {
            try {
                const _token = retrieveToken();
                if (_token === null) {
                    return true;
                }
                const _jwt = jwtDecode<EmoryToken>(_token);
                return isTokenExpired(_jwt);
            } catch (ex) {
                console.log("Unable to check token exp in inst func.");
                console.log(ex);
            }
            return true;
        },
        getExpireDate: function () {
            try {
                const _token = retrieveToken();
                if (_token === null) {
                    throw Error("unable to read token in getExpireDate");
                }
                const _jwt = jwtDecode<EmoryToken>(_token);
                return new Date(_jwt.exp * 1000);
            } catch (ex) {
                console.log(ex);
                console.log("Unable to get token expire date in getExpireDate");
            }
            return new Date("2000-01-01");
        }
    };
    return user;
}

const isTokenExpired = (jwt: EmoryToken) => {
    try {
        const expireDate = new Date(jwt.exp * 1000);
        const exp = moment(expireDate);
        return exp.isBefore(moment());
    } catch (ex) {
        console.log("Unable to check token expiration.");
        console.log(ex);
        return true;
    }
};
