import React, { useState } from 'react';
import { TextField } from "formik-material-ui";
import { AuthenticateResponse, AuthenticateRequest } from '../models/api';
import { saveToken, useUser, getUser } from '../models/auth';
import { Formik, Form, Field } from "formik";
import { client, getRemoteErrors } from '../models/client';
import { Typography, Button, MenuItem, Dialog, DialogContent, DialogTitle, DialogActions, Box } from '@material-ui/core';
import AdminTheme from './AdminTheme';
import ApplyDialog from './ApplyDialog';
import ResetPasswordDialog from './ResetPasswordDialog';

type InputProps = {
    open: boolean,
    onSuccess: () => void,
    onClose: () => void
}

const LoginDialog = ({ open, onSuccess, onClose }: InputProps) => {
    const { setUser } = useUser();
    const [showApply, setShowApply] = useState(false);
    const [showPasswordReset, setShowPasswordReset] = useState(false);

    return (
        <>
            <Formik<AuthenticateRequest & { generalError: string | null }>
                initialValues={{
                    type: "Emory", //"NonEmory",
                    username: "",
                    password: "",
                    generalError: null
                }}
                onSubmit={async (values, { setSubmitting, setErrors }) => {
                    try {
                        const { token } = await client.post<AuthenticateResponse>("/user/authenticate", values).then(o => o.data);
                        if (!token) {
                            throw new Error("Token respoinse missing");
                        }
                        saveToken(token);
                        setUser(getUser());
                        onSuccess();
                    } catch (ex) {
                        if (ex.response?.data) {
                            const errors = getRemoteErrors(ex.response.data);
                            console.log(errors);
                            setErrors(errors);
                        } else {
                            setErrors({
                                generalError: ex.message
                            });
                        }
                        setSubmitting(false);
                    }
                }}
            >
                {({ isSubmitting, errors, values }) => (
                    <Dialog
                        open={open}
                        onClose={onClose}
                        aria-labelledby="login-dialog-title"
                    >
                        <Form autoComplete="on">
                            <DialogTitle id="login-dialog-title" style={{ paddingBottom: "0" }}>Sign in</DialogTitle>
                            <DialogContent>
                                {errors.generalError && <Typography variant="subtitle1" gutterBottom color="error">{errors.generalError}</Typography>}
                                <Field
                                    name="type"
                                    type="text"
                                    label="Account Provider"
                                    select
                                    margin="normal"
                                    component={TextField}
                                    fullWidth
                                >
                                    <MenuItem value="Emory">Emory</MenuItem>
                                    <MenuItem value="NonEmory">Not Emory</MenuItem>
                                </Field>
                                <Field
                                    name="username"
                                    label={values.type === "Emory" ? "Emory NetID" : "Email address" }
                                    type="input"
                                    component={TextField}
                                    margin="normal"
                                    autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
                                    fullWidth
                                />
                                <Field
                                    name="password"
                                    label="Password"
                                    type="password"
                                    component={TextField}
                                    margin="normal"
                                    fullWidth
                                />
                            </DialogContent>
                            <DialogActions>
                                <Box display="flex" flexGrow="1">
                                    <AdminTheme>
                                        <Button onClick={() => setShowApply(true)} color="primary" variant="outlined" disabled={isSubmitting}>
                                            Apply for access
                                        </Button>
                                    </AdminTheme>
                                </Box>
                                <AdminTheme>
                                    {values.type === "NonEmory" && (
                                        <Button
                                            color="primary"
                                            disabled={isSubmitting}
                                            style={{ marginLeft: "0.5rem" }}
                                            onClick={() => setShowPasswordReset(true)}
                                        >
                                            Forgot password
                                        </Button>
                                    )}
                                    <Button onClick={onClose} color="primary" disabled={isSubmitting}>
                                        Cancel
                                    </Button>
                                </AdminTheme>
                                <Button type="submit" color="secondary" variant="contained" disabled={isSubmitting}>
                                    Sign in
                                </Button>
                            </DialogActions>
                        </Form>
                    </Dialog>
                )}
            </Formik>
            <ApplyDialog
                open={showApply}
                onClose={() => setShowApply(false)}
            />
            <ResetPasswordDialog
                open={showPasswordReset}
                onClose={() => setShowPasswordReset(false)}
            />
        </>
    );
}

export default LoginDialog;