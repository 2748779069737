import React from 'react';
import { TextField } from "formik-material-ui";
import { useGetUserEdit, UserViewModel, usePostUserEdit, UserPhoneViewModel, UserAddressViewModel } from '../models/api';
import { saveToken, useUser, getUser } from '../models/auth';
import { Formik, Form, Field, FieldProps } from "formik";
import { getRemoteErrors } from '../models/client';
import { Container, Card, CardContent, Typography, Button, Grid, TextField as MuiTextField, Paper } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import MaterialTable, { Column } from 'material-table';
import { PhoneTypes } from '../models/PhoneType';
import { UnitedStates } from '../models/UnitedStates';
import { useSnackbar } from 'notistack';

const EditUserPage = () => {
    const { setUser } = useUser();
    const { enqueueSnackbar } = useSnackbar();
    const { data: user, loading, error } = useGetUserEdit({});
    const { mutate } = usePostUserEdit({});
    const gridSpacing = 3;

    const pageTitle = "Update account";

    const isEmory = user?.type === "Emory";

    return (
        <Container maxWidth="md">
            {(loading || !user) && (
                <Card>
                    <CardContent>
                        <Typography variant="h5" gutterBottom>
                            {pageTitle}
                        </Typography>
                        {[0, 1, 2].map(i => (
                            <Skeleton key={i} variant="text"></Skeleton>
                        ))}
                    </CardContent>
                </Card>
            )}
            {error && (
                <Card>
                    <CardContent>
                        <Typography variant="h5" gutterBottom>
                            {pageTitle}
                        </Typography>
                        <Typography>
                            Unable to retrieve account information.  Please refresh the page.
                        </Typography>
                    </CardContent>
                </Card>
            )}
            {user && (
                <Card>
                    <CardContent>
                        <Formik<UserViewModel & { generalError: string | null }>
                            initialValues={{
                                ...user,
                                generalError: null
                            }}
                            onSubmit={async (values, { setSubmitting, setErrors }) => {
                                try {
                                    const { token } = await mutate(values);
                                    if (!token) {
                                        throw new Error("Unable to get updated token after user edit");
                                    }
                                    saveToken(token);
                                    setUser(getUser());
                                    enqueueSnackbar("Profile updated", { variant: "success" });
                                } catch (ex) {
                                    if (ex.data) {
                                        const errors = getRemoteErrors(ex.data);
                                        console.log(errors);
                                        setErrors(errors);
                                    } else {
                                        setErrors({
                                            generalError: ex.message
                                        });
                                    }
                                    setSubmitting(false);
                                }
                            }}
                        >
                            {({ isSubmitting, errors }) => (
                                <Form
                                    autoComplete="on"
                                >
                                    <Typography variant="h5" gutterBottom>
                                        {pageTitle}
                                    </Typography>
                                    {errors.generalError && <Typography variant="subtitle1" gutterBottom color="error">{errors.generalError}</Typography>}
                                    {isEmory && (
                                        <Typography variant="subtitle1" gutterBottom>
                                            Some of your account details come from the Emory employee directory.  
                                            If you wish to update your name or email address, please contact your human resources department.
                                        </Typography>
                                    )}
                                    <Grid container spacing={gridSpacing}>
                                        <Grid item xs={12} md={6}>
                                            <Field
                                                name="firstName"
                                                label="First Name"
                                                component={TextField}
                                                margin="normal"
                                                disabled={isEmory}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Field
                                                name="lastName"
                                                label="Last Name"
                                                component={TextField}
                                                margin="normal"
                                                disabled={isEmory}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                    <Field
                                        name="email"
                                        label="Email Address"
                                        component={TextField}
                                        margin="normal"
                                        disabled={isEmory}
                                        fullWidth
                                    />
                                    <Field
                                        name="company"
                                        label="Company Name"
                                        component={TextField}
                                        margin="normal"
                                        disabled={isEmory}
                                        fullWidth
                                    />
                                    <Field name="phones">
                                        {(fieldProps: FieldProps<UserPhoneViewModel[]>) => (
                                            <PropertyTable<UserPhoneViewModel>
                                                title="Phones"
                                                fieldProps={fieldProps}
                                                columns={[
                                                    {
                                                        field: "type",
                                                        title: "Type",
                                                        initialEditValue: "Mobile",
                                                        lookup: PhoneTypes
                                                    },
                                                    {
                                                        field: "number",
                                                        title: "Number",
                                                        initialEditValue: "",
                                                        editComponent: (props) => (
                                                            <MuiTextField
                                                                value={props.value ?? ""}
                                                                onChange={e => props.onChange(e.target.value)}
                                                                placeholder="(555) 555-5555"
                                                                fullWidth
                                                            />)
                                                    }
                                                ]}
                                            />
                                        )}
                                    </Field>
                                    <Field name="addresses">
                                        {(fieldProps: FieldProps<UserAddressViewModel[]>) => (
                                            <PropertyTable<UserAddressViewModel>
                                                title="Addresses"
                                                fieldProps={fieldProps}
                                                columns={[
                                                    {
                                                        field: "street",
                                                        title: "Street",
                                                        initialEditValue: "",
                                                        editComponent: (props) => (
                                                            <MuiTextField
                                                                value={props.value ?? ""}
                                                                onChange={e => props.onChange(e.target.value)}
                                                                fullWidth
                                                            />)
                                                    },
                                                    {
                                                        field: "city",
                                                        title: "City",
                                                        initialEditValue: "",
                                                        editComponent: (props) => (
                                                            <MuiTextField
                                                                value={props.value ?? ""}
                                                                onChange={e => props.onChange(e.target.value)}
                                                                fullWidth
                                                            />)
                                                    },
                                                    {
                                                        field: "state",
                                                        title: "State",
                                                        initialEditValue: "GA",
                                                        lookup: UnitedStates
                                                    },
                                                    {
                                                        field: "zip",
                                                        title: "Zip Code",
                                                        initialEditValue: "",
                                                        editComponent: (props) => (
                                                            <MuiTextField
                                                                value={props.value ?? ""}
                                                                onChange={e => props.onChange(e.target.value)}
                                                                // fullWidth
                                                            />)
                                                    }
                                                ]}
                                            />
                                        )}
                                    </Field>

                                    {!isEmory && (
                                        <>
                                            <Typography variant="h6" style={{ marginTop: "1em" }}>
                                                Update password
                                            </Typography>
                                            <Field
                                                name="password"
                                                label="New Password"
                                                component={TextField}
                                                margin="normal"
                                                fullWidth
                                            />
                                            <Field
                                                name="confirmPassword"
                                                label="Confirm New Password"
                                                component={TextField}
                                                margin="normal"
                                                fullWidth
                                            />
                                        </>
                                    )}

                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                        disabled={isSubmitting}
                                    >
                                        Update profile
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </CardContent>
                </Card>
            )}
        </Container>
    );
}

interface PropertyTable<RowData extends object> {
    title: string,
    columns: Column<RowData>[],
    fieldProps: FieldProps<RowData[]>
};

function PropertyTable<RowData extends object>({ title, columns, fieldProps }: PropertyTable<RowData>) {
    const { field, form } = fieldProps;
    return (
        <div style={{ maxWidth: "100%" }}>
            <MaterialTable
                title={<Typography variant="body1">{title}</Typography>}
                data={field.value ?? []}
                style={{
                    marginBottom: "1.8rem"
                }}
                options={{
                    paging: false,
                    search: false
                }}
                components={{
                    Container: (props: any) => <Paper {...props} elevation={0} />,
                    // Toolbar: props => (
                    //     <div>
                    //         <MTableToolbar {...props} style={{ paddingLeft: "8px" }} />
                    //     </div>
                    // )
                }}
                editable={{
                    onRowAdd: async (newData) => {
                        const _items = [
                            ...field.value,
                            newData
                        ];
                        form.setFieldValue(field.name, _items);
                    },
                    onRowUpdate: async (newData, oldData) => {
                        try {
                            if (!oldData) {
                                throw new Error("Could not read old data.");
                            }
                            const _items = [...field.value];
                            const index = _items.indexOf(oldData);
                            if (index === -1) {
                                throw new Error("Could not find index of old data.");
                            }
                            _items[index] = newData;
                            form.setFieldValue(field.name, _items);
                        } catch (err) {
                            console.log(err.message);
                            throw err;
                        }
                    },
                    onRowDelete: async (oldData) => {
                        const index = field.value.indexOf(oldData);
                        const _items = field.value.filter((o, i) => i !== index);
                        form.setFieldValue(field.name, _items);
                    }
                }}
                localization={{
                    header: {
                        actions: ""
                    }
                }}
                columns={columns}
            />
        </div>
    );
};


export default EditUserPage;