import React, { useState, useMemo, useEffect } from 'react';
import { usePostUserAdminAddEmory, AddEmoryUserRequest, PersonViewModel, UserSearchRequest } from '../../models/api';
import { Formik, Form, Field, FieldProps } from "formik";
import { client, getRemoteErrors } from '../../models/client';
import { Typography, Button, CircularProgress, FormHelperText, TextField as MuiTextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { ApiGeneralError } from '../../models/ApiGeneralError';
import { throttle } from 'lodash';
import Autocomplete from '@material-ui/lab/Autocomplete';

type InputProps = {
    onSubmit: () => void,
    onCancel: () => void,
    show: boolean
}

const AddEmoryUser = ({ onSubmit, onCancel, show }: InputProps) => {
    const { mutate: save } = usePostUserAdminAddEmory({});

    return (
        <Dialog open={show} onClose={onCancel} fullWidth aria-labelledby="form-dialog-title-addemory">
            <Formik<ApiGeneralError & { person: PersonViewModel | null }>
                initialValues={{
                    generalError: null,
                    person: null
                }}
                onSubmit={async (values, { setSubmitting, setErrors }) => {
                    try {
                        if (!(values.person?.directoryKey)) {
                            throw new Error("You must select a person.");
                        }
                        const dto: AddEmoryUserRequest = {
                            directoryKey: values.person.directoryKey
                        }
                        await save(dto);
                        onSubmit();
                    } catch (ex) {
                        console.log(ex);
                        if (ex.response?.data) {
                            const errors = getRemoteErrors(ex.response.data);
                            if (errors["directoryKey"]) {
                                errors["person"] = errors["directoryKey"];
                            }
                            setErrors(errors);
                        } else {
                            setErrors({
                                generalError: ex.message
                            });
                        }
                        setSubmitting(false);
                    }
                }}
            >
                {({ isSubmitting, errors, setFieldValue }) => (
                    <Form
                        autoComplete="on"
                    >
                        <DialogTitle id="form-dialog-title-addemory">Add Emory User</DialogTitle>
                        <DialogContent>
                            {errors.generalError && <Typography variant="subtitle1" gutterBottom color="error">{errors.generalError}</Typography>}
                            <Field name="person">
                                {(props: FieldProps<PersonViewModel>) => (
                                    <>
                                        {props.field.value === null && (
                                            <PersonSelect
                                                onSelected={(person) => {
                                                    setFieldValue("person", person);
                                                }}
                                                error={props.meta.error}
                                            />
                                        )}
                                        {props.field.value && (
                                            <div>
                                                <Typography variant="h5" color="textSecondary" gutterBottom style={{ marginTop: "1rem" }}>
                                                    Person
                                                        <Button
                                                        size="small"
                                                        color="primary"
                                                        onClick={() => setFieldValue("person", null)}
                                                        style={{ marginLeft: "0.5rem" }}
                                                    >
                                                        Clear selected user
                                                    </Button>
                                                </Typography>
                                                <MuiTextField
                                                    label="Name"
                                                    fullWidth
                                                    margin="normal"
                                                    InputProps={{ readOnly: true }}
                                                    value={props.field.value.name}
                                                />
                                                <MuiTextField
                                                    label="Title"
                                                    fullWidth
                                                    margin="normal"
                                                    InputProps={{ readOnly: true }}
                                                    value={props.field.value.title || "None"}
                                                />
                                                <MuiTextField
                                                    label="Phone"
                                                    fullWidth
                                                    margin="normal"
                                                    InputProps={{ readOnly: true }}
                                                    value={props.field.value.phone || "None"}
                                                />
                                                <MuiTextField
                                                    label="Email"
                                                    fullWidth
                                                    margin="normal"
                                                    InputProps={{ readOnly: true }}
                                                    value={props.field.value.email || "None"}
                                                />
                                            </div>
                                        )}
                                    </>
                                )}
                            </Field>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onCancel} color="primary" disabled={isSubmitting}>
                                Cancel
                            </Button>
                            <Button type="submit" color="primary" disabled={isSubmitting}>
                                Add User
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

type PersonSelectProps = {
    onSelected: (person: PersonViewModel | null) => void,
    error: string | undefined
};

const PersonSelect = ({ onSelected, error }: PersonSelectProps) => {
    const [options, setOptions] = useState<PersonViewModel[]>([]);
    const [inputValue, setInputValue] = useState<string>("");
    const [loading, setLoading] = useState(false);
    const hasError = Boolean(error);

    const fetch = useMemo(() =>
        throttle((value: string, callback: (results: PersonViewModel[]) => void) => {
            const dto: UserSearchRequest = {
                input: value
            };
            client.post<PersonViewModel[]>("/user/search/emory", dto)
                .then(response => response.data)
                .then(results => callback(results))
                .catch(error => console.log(error.toJSON()));
        }, 200), []
    );

    useEffect(() => {
        let active = true;

        if (inputValue === "") {
            setLoading(false);
            setOptions([]);
            return;
        }

        setLoading(true);
        fetch(inputValue, (results) => {
            if (active) {
                setLoading(false);
                setOptions(results);
            }
        });

        return () => {
            active = false;
        };
    }, [inputValue, fetch]);

    return (
        <>
            <Autocomplete
                getOptionSelected={(option: PersonViewModel, value: PersonViewModel) => option.directoryKey === value.directoryKey}
                getOptionLabel={(person: PersonViewModel) => {
                    let label = person.name;
                    if (person.title) {
                        label = `${label} - ${person.title}`;
                    }
                    return label;
                }}
                options={options}
                loading={loading}
                disableOpenOnFocus
                autoHighlight
                onInputChange={(_, value) => setInputValue(value)}
                onChange={(event: React.ChangeEvent<{}>, value: PersonViewModel | null) => {
                    onSelected(value);
                }}
                renderInput={params => (
                    <MuiTextField
                        label="Emory employee"
                        type="input"
                        margin="normal"
                        error={hasError}
                        fullWidth
                        {...params}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
            {hasError && <FormHelperText error={hasError}>{error}</FormHelperText>}
        </>
    );
};

export default AddEmoryUser;