import React, { useState } from 'react';
import { Link as RouterLink, useParams } from "react-router-dom";
import { Container, Card, CardContent, Typography, CardActions, Button } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { Form, Field, Formik } from 'formik';
import { ResetPasswordUpdateRequest, usePostUserResetPasswordUpdate } from "../models/api";
import { getRemoteErrors } from '../models/client';

type FormInput = ResetPasswordUpdateRequest & { generalError: string | null } & {
    confirmPassword: string,
    generalError: string | null
}

const PasswordResetPage = () => {
    const { code } = useParams();
    const { mutate: resetPassword } = usePostUserResetPasswordUpdate({});
    const [submitted, setSubmitted] = useState(false);

    if (submitted) {
        return (
            <Container maxWidth="sm">
                <Card>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            Password reset
                        </Typography>
                        <Typography variant="body1">
                            Your password has been updated.
                        </Typography>
                    </CardContent>
                    <CardActions style={{ flexDirection: "row-reverse" }}>
                        <Button color="secondary" variant="contained" component={RouterLink} to="/">View the directory</Button>
                    </CardActions>
                </Card>
            </Container>
        );
    }

    return (
        <Formik<FormInput>
            initialValues={{
                code: code ?? "",
                password: "",
                confirmPassword: "",
                generalError: null
            }}
            validate={(values => {
                const errors: Partial<FormInput> = {};
                if (!values.password) {
                    errors.password = "Required";
                }
                if (!values.confirmPassword) {
                    errors.confirmPassword = "Required";
                }
                if (values.password && values.confirmPassword && values.password !== values.confirmPassword) {
                    errors.confirmPassword = "Passwords do not match.";
                }
                return errors;
            })}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
                try {
                    await resetPassword({
                        code: values.code,
                        password: values.password
                    });
                    setSubmitted(true);
                } catch (ex) {
                    if (ex.data) {
                        const errors = getRemoteErrors(ex.data);
                        console.log(errors);
                        setErrors(errors);
                    } else {
                        setErrors({
                            generalError: ex.message
                        });
                    }
                    setSubmitting(false);
                }
            }}
        >
            {({ isSubmitting, errors, values }) => (
                <Form autoComplete="on">
                    <Container maxWidth="sm">
                        <Card>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Complete password reset
                                </Typography>
                                <Field
                                    name="password"
                                    label="Password"
                                    type="password"
                                    component={TextField}
                                    margin="normal"
                                    fullWidth
                                />
                                <Field
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    type="password"
                                    component={TextField}
                                    margin="normal"
                                    fullWidth
                                />
                            </CardContent>
                            <CardActions style={{ flexDirection: "row-reverse" }}>
                                <Button type="submit" color="secondary" variant="contained" disabled={isSubmitting}>
                                    Update password
                                </Button>
                            </CardActions>
                        </Card>
                    </Container>
                </Form>
            )}
        </Formik>
                
    );
}

export default PasswordResetPage;