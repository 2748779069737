export type PhoneType = "Mobile" | "Office" | "Fax";

export const PhoneTypesList: PhoneType[] = [
    "Mobile",
    "Office",
    "Fax"
];

export const PhoneTypes = {
    "Mobile": "Mobile",
    "Office": "Office",
    "Fax": "Fax"
};