import React from "react";
import { Avatar } from "@material-ui/core";
import { pictureUrl } from "../models/config";
import Person from "@material-ui/icons/Person"

type InputProps = {
    imageID: number | null | undefined,
    displayName: string,
    avatarSize: number
}

export const PhysicianAvatar = ({ imageID, displayName, avatarSize }: InputProps) => {
    const avatarStyles = {
        width: avatarSize,
        height: avatarSize
    };
    if (imageID) {
        return (
            <Avatar
                style={avatarStyles}
                alt={displayName}
                src={pictureUrl(imageID)}
            />
        );
    }

    return (
        <Avatar
            style={avatarStyles}
            alt={displayName}
        >
            <Person style={{ fontSize: (avatarSize * 0.9) }} />
        </Avatar>
    );
}