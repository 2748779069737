import React from 'react';
import { TextField } from "formik-material-ui";
import { ApplyEmoryRequest, usePostUserApplyEmory } from '../models/api';
import { Formik, Form, Field } from "formik";
import { getRemoteErrors } from '../models/client';
import { Typography, Button, DialogContent, DialogActions, DialogContentText } from '@material-ui/core';
import AdminTheme from './AdminTheme';

type InputProps = {
    onSubmit: () => void,
    onClose: () => void
}

const ApplyEmory = ({ onSubmit, onClose }: InputProps) => {
    const { mutate: applyEmory } = usePostUserApplyEmory({});

    return (
        <Formik<ApplyEmoryRequest & { generalError: string | null }>
            initialValues={{
                username: "",
                password: "",
                generalError: null
            }}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
                try {
                    await applyEmory(values);
                    onSubmit();
                } catch (ex) {
                    if (ex.data) {
                        const errors = getRemoteErrors(ex.data);
                        console.log(errors);
                        setErrors(errors);
                    } else {
                        setErrors({
                            generalError: ex.message
                        });
                    }
                    setSubmitting(false);
                }
            }}
        >
            {({ isSubmitting, errors }) => (
                <Form autoComplete="on">
                    <DialogContent>
                        {errors.generalError && <Typography variant="subtitle1" gutterBottom color="error">{errors.generalError}</Typography>}
                        <DialogContentText>
                            Sign in with your Emory credentials to apply for access.
                        </DialogContentText>
                        <Field
                            name="username"
                            label="Emory NetID"
                            component={TextField}
                            margin="normal"
                            fullWidth
                        />
                        <Field
                            name="password"
                            label="Password"
                            type="password"
                            component={TextField}
                            margin="normal"
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <AdminTheme>
                            <Button onClick={onClose} color="primary" disabled={isSubmitting}>
                                Cancel
                            </Button>
                        </AdminTheme>
                        <Button type="submit" color="secondary" variant="contained" disabled={isSubmitting}>
                            Apply
                        </Button>
                    </DialogActions>
                </Form>
            )}
        </Formik>
    );
}

export default ApplyEmory;